import { Injectable } from '@angular/core'
import sha256 from 'crypto-js/sha256'

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  constructor() {}

  encryptSha256(value: boolean) {
    const valueString = String(value)
    return sha256(valueString).toString()
  }
}
