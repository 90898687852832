import { uri } from 'config/urlConfig'

export const environment = {
  ...uri,
  production: true,
  PASSWORD_LOCKEDOUT: 'PASSWORD_LOCKEDOUT',
  SUCCESSFULL: 'SUCCESSFULL',
  CANCEL: 'CANCEL',
  USERNAME_AND_PASSWORD: 'USERNAME_AND_PASSWORD',
  QUESTION_AND_ANSWER: 'QUESTION_AND_ANSWER',
  REGISTRATION: 'REGISTRATION',
  SECURITY_QA: 'SECURITY_QA',
  SECURITY_CODE: 'SECURITY_CODE',
  FORCED_PW: 'FORCED_PW',
  UNSUCCESSFULL: 'UNSUCCESSFULL',
  TEMP_CREDENTIALS: 'TEMP_CREDENTIALS',
  AA_QA_LOCKEDOUT: 'AA_QA_LOCKEDOUT',
  AA_OOB_LOCKEDOUT: 'AA_OOB_LOCKEDOUT',
  AA_RESET: 'AA_RESET',
  OOB_ENROLL: 'OOB_ENROLL',
  CHALLENGE_OTP: 'CHALLENGE_OTP',
  OUT_OF_BAND: 'OUT_OF_BAND',
  CHALLENGE_OTP_TRANSMIT: 'CHALLENGE_OTP_TRANSMIT',
  OUT_OF_BAND_TRANSMIT: 'OUT_OF_BAND_TRANSMIT',
  OTP_CHALLENGE_FAILED: 'OTP_CHALLENGE_FAILED',
  AA_OTP_VALUE_EXPIRED: 'AA_OTP_VALUE_EXPIRED',
  OOB_EXPIRED_PASSCODE: 'OOB_EXPIRED_PASSCODE',
  OOB_INVALID_PASSCODE: 'OOB_INVALID_PASSCODE',
  FAILURE_TO_CREATE_OTP: 'FAILURE_TO_CREATE_OTP',
  INVALID_NUMBER: 'INVALID_NUMBER',
  SELF_SERVICE_OOB: 'SELF_SERVICE_OOB',
  SELF_SERVICE_OOB_TRANSMIT: 'SELF_SERVICE_OOB_TRANSMIT',
  QUESTION_AND_ANSWER_TRANSMIT: 'QUESTION_AND_ANSWER_TRANSMIT',
  LOGON_ADOBE_SECTION: 'utility',
  LOGON_ADOBE_SUBSECTION: 'logon',
  LOGON_ADOBE_PAGEID: 'logonhome',
  CHALLENGE_ADOBE_PAGEID: 'logonchallenge',
  OOB_CHALLENGE_ADOBE_PAGEID: 'oobchallene',
  ADOBE_SITE_SECTION_1: 'logon',
  ADOBE_OOB_SITE_SECTION_2: 'sign-up-to-receive-security-codes',
  ADOBE_SELF_SERVICE_SITE_USERNAME_SECTION_2: 'forgot-your-username',
  ADOBE_SELF_SERVICE_SITE_PASSWORD_SECTION_2: 'forgot-your-password',
  ADOBE_LOGON_SITE_SECTION_2: 'logon',
  ADOBE_LOGON_SITE_SECTION_3: 'enter-your-security-code',
  ADOBE_SITE_IDENTIFIER: 'iig',
  REG_TC_ADOBE_PAGEID: 'registrationt&c',
  REG_CRED_ADOBE_PAGEID: 'registrationcred',
  REG_QA_ADOBE_PAGEID: 'registrationq&a',
  SECURITY_QA_ADOBE_PAGEID: 'securityqa',
  FORCED_PW_ADOBE_PAGEID: 'forcedpw',
  FORGOT_PWD_ADOBE_PAGEID: 'forgotpassword',
  MISMATCH: 'MISMATCH',
  FORGOT_PASSWD: 'FORGOT_PASSWD',
  FORGOT_PASWD_SUCCESS: 'FORGOT_PASWD_SUCCESS',
  FORGOT_USER_SUCCESS: 'FORGOT_USER_SUCCESS',
  FORGOT_USERNAME: 'FORGOT_USERNAME',
  SELF_SERVICE_QA: 'SELF_SERVICE_QA',
  SELF_SERVICE_PASSWD: 'SELF_SERVICE_PASSWD',
  SELF_SERVICE: 'SELF_SERVICE',
  CALL_IN: 'CALL_IN',
  HOME_URL: '/',
  FAILURE_ERR_MSG:
    'An error occurred while retrieving your data. Please refresh the page and try again.',
  USERNAME: 'username',
  NEW_USERNAME: 'newusername',
  NEW_PASSWORD: 'newpassword',
  PASSWORD: 'password',
  TEMPPASSWORD: 'temppassword',
  REENTERPASSWORD: 'reenternewpassword',
  SECURITY_ANSWER_1: 'secAnswer1',
  SECURITY_ANSWER_REENTER_1: 'secReEnter1',
  SECURITY_ANSWER_2: 'secAnswer2',
  SECURITY_ANSWER_REENTER_2: 'secReEnter2',
  SECURITY_ANSWER_3: 'secAnswer3',
  SECURITY_ANSWER_REENTER_3: 'secReEnter3',
  EMAIL: 'email',
  TEXT: 'text',
  TELEPHONE: 'tel',
  OOB_CODE: 'oobcode',
  SEC: 'sec',
  FIRST_NAME: 'firstname',
  LAST_NAME: 'lastname',
  PLAN_NUMBER: 'plannumber',
  PHONE: 'phone',
  DOMESTIC_PHONE: 'domestic',
  PHONE_NUMBER: 'phonenumber',
  FREQ_ALWAYS: 'ALWY',
  FREQ_UNBOUND: 'UBND',
  TEXT_OPTION: 'SMS_TEXT',
  INTL_TEXT_OPTION: 'INTL_SMS_TEXT',
  CALL_OPTION: 'AUTOMATED_CALL',
  SECURITY_QNA: 'securityQnA',
  INVALID_LOGON_CREDENTIALS: 'INVALID_LOGON_CREDENTIALS',
  GENERIC_ERROR: 'GENERIC_ERROR',
  MAX_AA_SESSIONS_REACHED: 'MAX_AA_SESSIONS_REACHED',
  USERNAME_NA: 'USERNAME_NA',
  AA_CHALLENGE_EXPIRED: 'AA_CHALLENGE_EXPIRED',
  TRANSMIT_USER_STATUS_C: 'C',
  TRANSMIT_USER_STATUS_F: 'F',
  USER_STATUS_C: 'USER_STATUS_C',
  USER_STATUS_F: 'USER_STATUS_F',
  AA_ENROLL: 'AA_ENROLL',
  RESTRICTED_TO_BOUND_DEVICE: 'RESTRICTED_TO_BOUND_DEVICE',
  AA_CHALLENGE_FAILED: 'AA_CHALLENGE_FAILED',
  CONTENT_ROUTE: 'content.json',
  TC_CONTENT_ROUTE: 'tcContent.json',
  REGION_ROUTE: 'region.json',
  GATEKEEPER: 'gatekeeper',
  GATEKEEPER_MULTI: 'gatekeeper/multi',
  CLIENT_LOG: 'client-log',
  SIGNOUT: 'signout',
  UNDER_MAINTENANCE: 'UnderMaintenance',
  INTERNATIONAL_OOB: 'International-OOB-Enrollment',
  MPM_SELFSERVICE: 'mpm-selfService',
  TRANSMIT_DEFAULT_OTP: 'transmitDefaultOtp',
  APP_DOWN: 'AppDown',
  LOGOFF: 'LOGOFF',
  NONE: 'NONE',
  LOCKED: 'locked',
  TRUE: 'true',
  EM_DASH: '�',
  ALPHA_NUM_SPECIAL_CHAR_REG_EX: '^[a-zA-Z0-9!@#$%^&*()]+$',
  PHONE_NUM_REG_EX: '^[0-9!+ ]+$',
  USERNAME_REGEX: '^[a-zA-Z0-9]*$',
  NAME_REGEX: "^(?!-|')[ a-zA-Z0-9'-]*$",
  NUMBER_REGEX: '^[0-9]+$',
  OOB_TC_BULLET: 'bullet',
  FORGOT_PASSWORD_ROUTE: '/forgot-password',
  FORGOT_USERNAME_ROUTE: '/forgot-username',
  PRODUCTION: 'production',
  HTTP: 'http',
  TOKEN_NOT_VERIFIED: 'TOKEN_NOT_VERIFIED',
  F_PW_TEMP_PASSWORD: 'temppassword',
  F_PW_NEW_PASSWORD: 'newpassword',
  F_PW_REENTER_PASSWORD: 'reenternewpassword',
  SECURITY_ANSWER_INPUT_1: 'secanswerinput1',
  SECURITY_ANSWER_REENTER_INPUT_1: 'secreenterinput1',
  SECURITY_ANSWER_INPUT_2: 'secanswerinput2',
  SECURITY_ANSWER_REENTER_INPUT_2: 'secreenterinput2',
  SECURITY_ANSWER_INPUT_3: 'secanswerinput3',
  SECURITY_ANSWER_REENTER_INPUT_3: 'secreenterinput3',
}
