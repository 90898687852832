<div [ngSwitch]="view">
    <div *ngSwitchCase="'USERNAME_AND_PASSWORD'">
      <logon-view></logon-view>
    </div>
    <div *ngSwitchCase="'QUESTION_AND_ANSWER'">
        <challenge-view></challenge-view>
    </div>
    <div *ngSwitchCase="'REGISTRATION'">
        <registration-view></registration-view>
    </div>
    <div *ngSwitchCase="'SECURITY_QA'">
        <security-qa-view></security-qa-view>
    </div>
    <div *ngSwitchCase="'FORCED_PW'">
        <forced-pw-view></forced-pw-view>
    </div>
    <div *ngSwitchCase="'SECURITY_CODE'">
        <security-code-view></security-code-view>
    </div>
    <div *ngSwitchCase="'OUT_OF_BAND'">
        <forced-oob-enrollment-view></forced-oob-enrollment-view>
    </div>
    <div *ngSwitchCase="'SELF_SERVICE_QA'">
        <self-service-qa-view></self-service-qa-view>
    </div>
</div>

