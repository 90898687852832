import { Injectable } from '@angular/core'
import { XmSdk } from '@vanguard/transmit/src/xm/js/xmsdk.js'
import { environment } from '@env'
import { RegionService } from '@app/shared/services/regionService'
import {
  Transmit,
  TransmitAuthenticateResult,
  TransmitErrorCode,
} from '@app/models/TransmitConstants'
import { TransmitJWTPayLoad } from '@app/models/TransmitJWTPayLoad'
import { TransmitJWTValidationService } from './transmitJWTValidation.service'
import { SessionService } from '@app/shared/services/sessionService'
import { ErrorMsgService } from '@app/shared/services/errorMsgService'
import { LoggerService } from '../loggerService'

const sdk = XmSdk()
declare const com: any

@Injectable({
  providedIn: 'root',
})
export class TransmitSdkService {
  private readonly apiTokenId = ''
  private readonly apiToken = ''
  private ticket
  private bound
  private loginCredentials
  private transmitParams
  public selfServiceFlowFlag = false
  public transmit_retry_counter = 0
  public userLockedStatus: string
  public token: string
  public isEnroll = false
  public errorCode
  constructor(
    private readonly regionService: RegionService,
    private session: SessionService,
    private readonly transmitJWTValidationService: TransmitJWTValidationService,
    private errorMsgService: ErrorMsgService,
    private loggerService: LoggerService,
  ) {}

  authenticate(
    ticket,
    bound,
    poid,
    journeyName,
    transmitParams?,
  ): Promise<TransmitAuthenticateResult> {
    const self = this
    const loginCredentials = {
      transmitUrl:
        environment[this.regionService.envType][this.regionService.userType]
          .TRANSMIT_URL,
      poid,
      policyId: journeyName,
      appid: self.isEnroll ? Transmit.ENROLLMENT_APP_ID : Transmit.APP_ID,
    }
    return new Promise(async (resolve) => {
      if (this.checkPoid(loginCredentials.poid) && ticket) {
        this.clearTransmitSession()
        const connectionSettings =
          this.createConnectionSettings(loginCredentials)
        sdk.setConnectionSettings(connectionSettings)
        sdk.setEnabledCollectors([])
        const startTime = new Date().getTime()

        this.transmit_retry_counter = 0

        const transmitPromise: Promise<TransmitAuthenticateResult> =
          this.authTransmit(ticket, bound, loginCredentials, transmitParams)

        transmitPromise
          .then((result: TransmitAuthenticateResult) => {
            if (result === TransmitAuthenticateResult.SUCCESSFUL) {
              this.loggerService.info(
                'Time taken to successfully complete Transmit Journey:' +
                  loginCredentials.policyId +
                  ', poId: ' +
                  loginCredentials.poid +
                  ', time: ' +
                  (new Date().getTime() - startTime) +
                  'ms',
              )
            }
            resolve(result)
          })
          .catch((err) => {
            this.loggerService.error(
              'Error Invoking Transmit Journey:' +
                loginCredentials.policyId +
                ', poId:' +
                loginCredentials.poid +
                ', Error -' +
                JSON.stringify(err),
            )

            resolve(TransmitAuthenticateResult.FAIL)
          })
      } else {
        // TODO remove logic when we expect 100% traffic for Fingerprint
        this.loggerService.error(
          'Error Invoking Transmit Journey:' +
            loginCredentials.policyId +
            ', Poid or Transmit Ticket missing - Poid:' +
            loginCredentials.poid +
            ', Transmit Ticket: ' +
            ticket,
        )
        resolve(TransmitAuthenticateResult.FAIL)
      }
    })
  }

  sdkAuthenticate = (
    initResult: InitializeResult,
  ): Promise<AuthenticateResult> => {
    const self = this
    return new Promise((resolve) => {
      if (initResult.result === true) {
        sdk
          .authenticate(
            initResult.poid,
            initResult.policyId,
            initResult.additionalParams,
            initResult.clientContext,
          )
          .then(async function (successfulAuth) {
            self.selfServiceFlowFlag = self.session.selfService // do we need this???

            if (self.selfServiceFlowFlag || self.isEnroll) {
              const transmitJwtPayload: TransmitJWTPayLoad = {
                poid: initResult.poid.toString(),
                policyId: initResult.policyId,
                token: successfulAuth.getToken().toString(),
                environment: self.regionService.angular_env,
              }
              self.token = transmitJwtPayload.token

              const jwtValidResponse =
                self.transmitJWTValidationService.validateJWT(
                  transmitJwtPayload,
                )

              jwtValidResponse.subscribe(
                (data) => {
                  if (data.response === 'Token Verified') {
                    //redirect to successful login page
                    resolve({
                      result: TransmitAuthenticateResult.SUCCESSFUL,
                    })
                    self.loggerService.info(
                      'Token Verified For ' + 'poId: ' + initResult.poid,
                    )
                    self.selfServiceFlowFlag = false
                  } else {
                    resolve({
                      result: TransmitAuthenticateResult.FAIL,
                    })
                    self.loggerService.info(
                      'Token Not Verified For ' + 'poId: ' + initResult.poid,
                    )
                    self.selfServiceFlowFlag = false
                  }
                },
                (error) => {
                  //show technical error

                  resolve({
                    result: TransmitAuthenticateResult.FAIL,
                  })
                  self.selfServiceFlowFlag = false
                  this.loggerService.error('JWT Validation Error :' + error)
                },
              )
            }
          })
          .then(() => {
            resolve({
              result: TransmitAuthenticateResult.SUCCESSFUL,
            })
            this.loggerService.info(
              'Successfully authenticated ' + 'poId: ' + initResult.poid,
            )
          })
          .catch((err) => {
            let authResult = TransmitAuthenticateResult.FAIL

            //SDK Retry Process when user is locked out, after 2 retry process should stop
            if (self.transmit_retry_counter < 2) {
              self.transmit_retry_counter++

              this.errorCode = err._errorCode

              if (this.userLockedStatus === 'LOCKOUT') {
                authResult = TransmitAuthenticateResult.LOCKOUT
              } else if (
                this.errorCode === TransmitErrorCode.AuthenticatorLocked ||
                this.errorCode === TransmitErrorCode.AllAuthenticatorsLocked ||
                (err._data &&
                  err._data.additional_data !== undefined &&
                  err._data.additional_data.locked === true) ||
                JSON.stringify(err).includes('LOCKOUT')
              ) {
                authResult = TransmitAuthenticateResult.LOCKOUT
                if (!this.isEnroll) {
                  this.loginCredentials.policyId = 'exception_journey'
                  this.loggerService.warn(
                    'Lockout for Transmit Journey: ' +
                      initResult.policyId +
                      ', poId: ' +
                      initResult.poid +
                      ', Error: ' +
                      JSON.stringify(err),
                  )

                  sdk.cancelCurrentRunningControlFlow()
                  //The self service flag was added in order to tell transmit to return the RSA bound value if it is not self service.  For self service it will always
                  //be false.
                  return this.sdkInitialize(
                    this.ticket,
                    this.bound,
                    this.loginCredentials,
                    this.transmitParams,
                    this.selfServiceFlowFlag,
                  )
                    .then(this.sdkAuthenticate)
                    .then(this.sdkLogout)
                    .then(() => {
                      resolve({
                        result: TransmitAuthenticateResult.LOCKOUT,
                      })
                    })
                } else {
                  sdk.cancelCurrentRunningControlFlow()
                }
              } else {
                if (
                  (JSON.stringify(err).includes('Device not found') &&
                    JSON.stringify(err).includes('"server_error_code":3001')) ||
                  err._errorCode === TransmitErrorCode.InitiateErrorCode
                ) {
                  this.loggerService.info(
                    'SDK initiliazing again for poid: ' +
                      initResult.poid +
                      ' for Transmit Journey: ' +
                      initResult.policyId,
                  )
                  return this.sdkInitialize(
                    this.ticket,
                    this.bound,
                    this.loginCredentials,
                    this.transmitParams,
                    this.selfServiceFlowFlag,
                  )
                    .then(this.sdkAuthenticate)
                    .then(this.sdkLogout)
                } else if (JSON.stringify(err).includes('locked')) {
                  authResult = TransmitAuthenticateResult.LOCKOUT
                } else if (
                  this.errorCode === TransmitErrorCode.ControlFlowExpired ||
                  this.errorCode === TransmitErrorCode.SessionRequired
                ) {
                  this.errorMsgService.invalidMessage = 'answerExpired'
                  authResult = TransmitAuthenticateResult.EXPIRED
                } else {
                  this.loggerService.error(
                    'Authenticate error for Transmit Journey: ' +
                      initResult.policyId +
                      ', poId: ' +
                      initResult.poid +
                      ', Error: ' +
                      JSON.stringify(err),
                  )
                }
              }
            } else {
              sdk.cancelCurrentRunningControlFlow()
              self.transmit_retry_counter = 0
            }
            resolve({
              result: authResult,
            })
          })
      } else {
        resolve({
          result: TransmitAuthenticateResult.FAIL,
        })
      }
    })
  }

  sdkLogout = (
    authResult: AuthenticateResult,
  ): Promise<TransmitAuthenticateResult> => {
    return new Promise((resolve, reject) => {
      if (authResult.result === TransmitAuthenticateResult.SUCCESSFUL) {
        TransmitSdkService.prototype.isEnroll = false
        sdk
          .logout()
          .then(() => {
            resolve(authResult.result)
          })
          .catch((err) => {
            if (err._errorCode === TransmitErrorCode.InitiateErrorCode) {
              resolve(authResult.result)
            } else {
              this.loggerService.error(
                'Logout error from Transmit SDK for ' +
                  'poId: ' +
                  this.session.poid +
                  ', Error: ' +
                  JSON.stringify(err),
              )
              resolve(TransmitAuthenticateResult.FAIL)
            }
          })
      } else {
        resolve(authResult.result)
      }
    })
  }

  sdkInitialize = (
    ticket,
    bound,
    loginCredentials,
    transmitParams?,
    selfServiceFlowFlag?,
  ): Promise<InitializeResult> => {
    let additionalParams
    this.ticket = ticket
    this.bound = bound
    this.loginCredentials = loginCredentials
    this.transmitParams = transmitParams
    selfServiceFlowFlag = this.selfServiceFlowFlag

    if (transmitParams) {
      const devicePrint = transmitParams.devicePrint
      const auth1 = transmitParams.auth1
      const auth2 = transmitParams.auth2
      const retailParams = transmitParams.retailParams
      const isOOBBypassClient = transmitParams.isOOBBypassClient
      additionalParams = {
        ticket,
        bound,
        auth1,
        auth2,
        devicePrint,
        retailParams,
        selfServiceFlowFlag,
        isOOBBypassClient,
      }
    } else {
      additionalParams = { ticket, bound }
    }

    const clientContext = {}
    return new Promise((resolve, reject) => {
      sdk
        .initialize()
        .then(() => {
          resolve({
            result: true,
            poid: loginCredentials.poid,
            policyId: loginCredentials.policyId,
            additionalParams,
            clientContext,
          })
        })
        .catch((err) => {
          this.loggerService.error(
            'Error Initializing Transmit SDK for Journey: ' +
              loginCredentials.policyId +
              ', poId: ' +
              loginCredentials.poid +
              ', Error: ' +
              JSON.stringify(err),
          )
          resolve({
            result: false,
          })
        })
    })
  }

  private clearTransmitSession() {
    const sessionItemName = 'currentSession'
    if (sessionStorage.getItem(sessionItemName)) {
      sessionStorage.removeItem(sessionItemName)
    }
    const localStorageItemName = 'users'
    if (localStorage.getItem(localStorageItemName)) {
      localStorage.removeItem(localStorageItemName)
    }
  }

  private createConnectionSettings(loginCredentials) {
    return com.ts.mobile.sdk.SDKConnectionSettings.create(
      loginCredentials.transmitUrl,
      loginCredentials.appid,
      this.apiTokenId,
      this.apiToken,
    )
  }

  private checkPoid(poid) {
    let result = false
    if (poid && poid !== '0') {
      result = true
    }
    return result
  }

  authTransmit = (
    ticket,
    bound,
    loginCredentials,
    transmitParams,
  ): Promise<TransmitAuthenticateResult> => {
    return this.sdkInitialize(
      ticket,
      bound,
      loginCredentials,
      transmitParams,
      this.selfServiceFlowFlag,
    )
      .then(this.sdkAuthenticate)
      .then(this.sdkLogout)
  }

  MyUIHandler() {
    XmSdk.call(this)
  }

  setUiHandler() {
    this.MyUIHandler.prototype.constructor = this.MyUIHandler
    this.MyUIHandler.prototype.startActivityIndicator = function (a) {
      const loader = document.createElement('div')
      loader.style.display = 'none'
    }

    this.MyUIHandler.prototype.getInformationResponse = this.createOkResponse

    this.MyUIHandler.prototype.handlePolicyRejection = this.createOkResponse

    const myHandler = new this.MyUIHandler()
    sdk.setUiHandler(myHandler)
  }

  createOkResponse() {
    return new Promise((resolve, reject) => {
      const okResponseNumber = -1
      resolve(com.ts.mobile.sdk.ConfirmationInput.create(okResponseNumber))
    })
  }

  setSdkUiHandler(handler) {
    if (handler) {
      sdk.setUiHandler(handler)
    } else {
      this.setUiHandler()
    }
  }
}

class InitializeResult {
  result: boolean
  poid?: number
  policyId?: string
  additionalParams?: any
  clientContext?: any
  selfServiceFlow?: any
}

class AuthenticateResult {
  result: TransmitAuthenticateResult
}
