import { SecurityCodeComponent } from '@app/securityCode/securityCode.component'
import { TransmitErrorCode, OtpAuthState } from '@app/models/TransmitConstants'
import { TransmitOtpService } from '@app/shared/services/transmitOtpService/transmitOtp.service'
import { environment } from '@env'
import { RegionService } from '@app/shared/services/regionService'
import { SessionService } from '@app/shared/services/sessionService'
import { Logon } from '@app/shared/services/logonService'
import { CancelResponse } from '@app/models/CancelResponse'
import { ViewService } from '@app/shared/services/viewService'

declare const com: any

export class TransmitOtpAuthSession {
  securityCodeComponent: SecurityCodeComponent
  transmitOtpService: TransmitOtpService
  mode: any
  primarySmsTarget: any
  primaryVoiceTarget: any
  primaryMethod: string

  constructor(securityCodeComponent, transmitOtpService) {
    this.securityCodeComponent = securityCodeComponent
    this.transmitOtpService = transmitOtpService
  }

  startSession = (description, mode, actionContext, clientContext) => {
    this.mode = mode
    this.transmitOtpService.actionContext = actionContext
  }

  endSession() {}

  setAvailableTargets(targets) {
    this.transmitOtpService.possibleTargets = targets
    this.transmitOtpService.possibleTargetAng = targets
  }

  getErrorCode() {}

  setGeneratedOtp(format, target) {
    if (target) {
      this.transmitOtpService.state = 1
      if (format instanceof com.ts.mobile.sdk.OtpFormatNumericImpl) {
        this.transmitOtpService.codeLength = format.getOtpLength()
        this.transmitOtpService.isSupportedFormat = true
      } else {
        this.transmitOtpService.isSupportedFormat = false
      }
    } else {
      this.transmitOtpService.state = 0
    }
    this.transmitOtpService.target = target
  }

  showAuthUi() {
    let inputPromise
    switch (this.transmitOtpService.state) {
      case OtpAuthState.TargetSelection:
        this.sendOtpToTarget()
        break
      case OtpAuthState.Input:
        if (this.transmitOtpService.isSupportedFormat) {
          this.showOtpInput()
        } else {
          Promise.reject('Reject due to unsupported otp code format')
        }
    }
    return inputPromise
  }

  sendOtpToTarget() {
    this.transmitOtpService.state = 1
    const target = this.securityCodeComponent.selfService
      ? this.transmitOtpService.possibleTargets[0]
      : this.getInitialTarget()
    const input =
      com.ts.mobile.sdk.TargetBasedAuthenticatorInput.createTargetSelectionRequest(
        target,
      )
    this.transmitOtpService.resolver(
      com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(input),
    )
  }

  showOtpInput() {
    if (this.securityCodeComponent.resendRequest) {
      this.securityCodeComponent.popUpMsg = 'resendSuccess'
      this.securityCodeComponent.warningBanner.setWarningBannerMessage(
        'resendSuccess',
      )
      this.securityCodeComponent.resendRequest = false
      this.securityCodeComponent.counter++
      this.securityCodeComponent.checkCounter()
    }
    this.securityCodeComponent.loading = false
  }

  getInitialTarget() {
    let target
    const otpTargets = this.transmitOtpService.possibleTargetAng
    this.primaryMethod =
      this.securityCodeComponent.oobChallengeService.logOnResponse.challenge?.USER_CHALLENGE_METHOD
    if (this.primaryMethod === 'SMS' || this.primaryMethod === 'INTL_SMS') {
      target = otpTargets.find(
        (t) =>
          t._targetIdentifier === '1' &&
          t._channel === com.ts.mobile.sdk.OtpChannel.Sms,
      )
    } else {
      target = otpTargets.find(
        (t) =>
          t._targetIdentifier === '1' &&
          t._channel === com.ts.mobile.sdk.OtpChannel.VoiceCall,
      )
    }
    return target
  }

  promiseRecoveryForError(error, validRecoveries, defaultRecovery) {
    let promiseRecovery
    return new Promise((resolve) => {
      if (
        error._errorCode === TransmitErrorCode.InvalidInput &&
        error._data.additional_data.additional_error_code === undefined
      ) {
        this.securityCodeComponent.popUpMsg = 'serviceFailureLogon'
        this.securityCodeComponent.warningBanner.setWarningBannerMessage(
          'invalidCode',
        )
        this.securityCodeComponent.loading = false
        promiseRecovery = defaultRecovery
        resolve(promiseRecovery)
        this.securityCodeComponent.resetForm()
      } else if (
        error._errorCode === TransmitErrorCode.InvalidInput &&
        error._data.assertion_error_code === TransmitErrorCode.OtpExpired
      ) {
        this.securityCodeComponent.popUpMsg = 'expiredCode'
        this.securityCodeComponent.warningBanner.setWarningBannerMessage(
          'expiredCode',
        )
        this.securityCodeComponent.securityCodeForm.controls['oobCode'].reset()
        this.securityCodeComponent.loading = false
        this.securityCodeComponent.securityCodeForm.controls[
          'oobCode'
        ].disable()
        this.securityCodeComponent.securityCodeForm.setErrors({
          invalid: true,
        })
        document.getElementById('sendNewCode').onclick = () => {
          promiseRecovery = defaultRecovery
          resolve(promiseRecovery)
        }
      } else if (
        (error._errorCode === TransmitErrorCode.InvalidInput &&
          error._data !== undefined &&
          error._data.additional_data.additional_error_code ===
            TransmitErrorCode.InvalidInput) ||
        error._errorCode === TransmitErrorCode.ControlFlowExpired ||
        error._errorCode === TransmitErrorCode.SessionRequired
      ) {
        promiseRecovery = defaultRecovery
        resolve(promiseRecovery)
        //set the banner here for expired session
        this.securityCodeComponent.warningBanner.setWarningBannerMessage(
          'answerExpired',
        )
        this.securityCodeComponent.cancel()
        this.securityCodeComponent.resetForm()
      } else {
        this.securityCodeComponent.popUpMsg = 'serviceFailureLogon'
        this.securityCodeComponent.errorMsgService.invalidMessage =
          'serviceFailureLogon'
        promiseRecovery = com.ts.mobile.sdk.AuthenticationErrorRecovery.Fail
        resolve(promiseRecovery)
        this.securityCodeComponent.resetForm()
      }
    })
  }

  handleAuthenticateAssertionResult(controlFlowResponse) {}

  promiseInput() {
    return new Promise((accept, reject) => {
      this.transmitOtpService.resolver = accept
      this.showAuthUi()
    }).then((input) => {
      this.transmitOtpService.authError = null
      return input
    })
  }
}
