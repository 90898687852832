import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core'
import { HeaderTextService } from '@app/shared/services/headerTextService'
import { SessionService } from '@app/shared/services/sessionService'
import { environment } from '@env'
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
  UntypedFormControl,
} from '@angular/forms'
import { AdobeAnalyticsService } from '@app/shared/services/adobe-analytics/adobe-analytics.service'
import { SecurityQNAService } from '@app/shared/services/securityQNAService'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { ViewService } from '@app/shared/services/viewService'
import { from, Subscription } from 'rxjs'
import { find } from 'rxjs/operators'
import { ContentService } from '@app/shared/services/contentService'
import { Logon } from '@app/shared/services/logonService'
import { RegionService } from '@app/shared/services/regionService'
import { twoAlphaTwoNumValidator } from '@app/shared/validators/twoAlphaTwoNumValidator'
import { SecurityQuestionsResponse } from '@app/models/SecurityQuestionsResponse'
import { confirmationValidator } from '@app/shared/validators/confirmationValidator'
import { SecurityAnswersPayload } from '@app/models/SecurityAnswersPayload'
import { SecurityAnswersResponse } from '@app/models/SecurityAnswersResponse'
import { WindowRefService } from '@app/shared/services/window-ref'
import { identicalControlValidator } from '@app/shared/validators/identicalControlValidator'
import { sameUsernameValidator } from '@app/shared/validators/sameUsernameValidator'
import { usernameOrPasswordStrValidator } from '@app/shared/validators/usernameOrPasswordStrValidator'
import { usernamePwdMatchValidator } from '@app/shared/validators/usernamePwdMatchValidator'
import { RegisterService } from '@app/shared/services/registerService'
import { RegisterPayload } from '@app/models/RegisterPayload'
import { RegisterResponse } from '@app/models/RegisterResponse'
import { CancelResponse } from '@app/models/CancelResponse'
import { ErrorMsgService } from '@app/shared/services/errorMsgService'
import { WarningBannerService } from '@app/shared/services/warningBannerService'
import { QuestionSelection } from '@app/models/QuestionSelection'
import { VuiDialogConfig, DialogComponent } from '@vanguard/vui/dialog'
import { TransmitTicketService } from '@app/shared/services/transmitCommonService/transmitTicket.service'
import { TransmitTicketPayload } from '@app/models/TransmitTicketPayload'
import {
  ACCOUNT_ID,
  ENROLL_TRANSACTION_TYPE,
  JOURNEY_ID,
  OOB_CHALLENGE_TYPE,
  POLICY_ID,
  SERVICE_ID,
  TICKET_APP_ID,
  Transmit,
  TRANSMIT_TICKET_URL,
} from '@app/models/TransmitConstants'
import { TransmitTicketResponse } from '@app/models/TransmitTicketResponse'
import { TransmitTicketHelperService } from '@app/shared/services/transmitCommonService/transmitTicketHelper.service'
import { CommonUIhandler } from '@vanguard/transmit/src/commonUIHandler'
import { GetQuestions } from '@vanguard/transmit/src/getQuestions'
import { ReturnToken } from '@vanguard/transmit/src/returnToken'
import * as jose from 'jose'
import { TransmitJWTPayLoad } from '@app/models/TransmitJWTPayLoad'
import { TransmitJWTValidationService } from '@app/shared/services/transmitCommonService/transmitJWTValidation.service'
import { TransmitEmailService } from '@app/shared/services/transmitEmailService/transmitEmail.service'
import { EmailTemplate } from '@app/models/EmailTemplate'
import { sameAnswerValidator } from '@app/shared/validators/sameAnswerValidator/sameAnswerValidator.validator'
import { ValidatorService } from '@app/shared/services/validatorService'
import { LoggerService } from '@app/shared/services/loggerService'
import { SelectState } from '@vg-constellation/angular-15/select'

const vuiValid = 'vui-valid'
const vuiInvalid = 'vui-invalid'

@Component({
  selector: 'registration-view',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  providers: [DialogComponent],
})
export class RegistrationComponent implements OnInit {
  dialogRef: MatDialogRef<any, any>
  @ViewChild('cancelDialog', { static: false })
  private cancelDialog: TemplateRef<any>
  @ViewChild('tipsContentDialog', { static: false })
  private tipsContentDialog: TemplateRef<any>
  @ViewChild('termsAndConditionDialog', { static: false })
  private termsAndConditionDialog: TemplateRef<any>

  vuiDialogConfig: VuiDialogConfig = {
    dialogType: 'simple',
    backdropDismiss: true,
  }
  content: any
  userName: string
  noRedirect: Promise<boolean>
  checkedTC = false
  tcView = true
  stepContainerView = true
  registerServiceResponse: { state: string }
  stepOneView = false
  stepTwoView = false
  disableForm = false
  securityQuestions: SecurityQuestionsResponse['questionGroup']
  selected1: string
  selected2: string
  selected3: string
  answerId1: string
  answerId2: string
  answerId3: string
  securityAnswersPayload: SecurityAnswersPayload
  registrationForm: FormGroup
  private validPattern = /^[\w\s]*$/
  cancelRestEndPoint: string
  invalidMessage = ''
  newPasswordInstruction: string[]
  newUsernameInstruction: string[]
  adobeData: { section: string; subSection: string; pageId: string }
  registerUrl: string
  registerPayload: RegisterPayload
  loading: boolean
  reviewTC = false
  securityQuestionsForm: FormControl[] = [
    new FormControl(null, Validators.required),
    new FormControl(null, Validators.required),
    new FormControl(null, Validators.required),
  ]
  securityQuestionsForm1 = new UntypedFormControl('', [Validators.required])
  securityQuestionsForm2 = new UntypedFormControl('', [Validators.required])
  securityQuestionsForm3 = new UntypedFormControl('', [Validators.required])
  securityQuestions1 = []
  securityQuestions2 = []
  securityQuestions3 = []
  transmitEnabledUser = false
  transmitTicketPayload: TransmitTicketPayload
  ticketResponse: TransmitTicketResponse
  transmitEmailNotificationUrl: string
  tempPasswordErrorMsg = ''
  newUsernameErrorMsg = ''
  newPasswordErrorMsg = ''
  reenterNewPasswordErrorMsg = ''
  secAnswer1ErrorMsg = ''
  secAnswer2ErrorMsg = ''
  secAnswer3ErrorMsg = ''
  secReEnter1ErrorMsg = ''
  secReEnter2ErrorMsg = ''
  secReEnter3ErrorMsg = ''
  private dialogOpen = false

  constructor(
    private fb: FormBuilder,
    private headerText: HeaderTextService,
    private warningBanner: WarningBannerService,
    private session: SessionService,
    private adobeAnalytics: AdobeAnalyticsService,
    private securityQNAService: SecurityQNAService,
    private contentService: ContentService,
    private viewContainer: ViewContainerRef,
    public vuiDialog: DialogComponent,
    private regionService: RegionService,
    private windowRef: WindowRefService,
    private registerService: RegisterService,
    private errorMsgService: ErrorMsgService,
    private viewService: ViewService,
    private logonService: Logon,

    private ticketService: TransmitTicketService,
    private ticketHelperService: TransmitTicketHelperService,
    private readonly transmitJWTValidationService: TransmitJWTValidationService,
    private transmitEmailService: TransmitEmailService,
    private validatorService: ValidatorService,
    private readonly loggerService: LoggerService,
  ) {}

  securityQNAForm = this.fb.group({
    secAnswerInput1: new FormControl('', [
      Validators.required,
      Validators.maxLength(50),
      Validators.pattern(this.validPattern),
      sameUsernameValidator(this.session.userName), //this validator is used to check for username in answers
      sameAnswerValidator('secAnswerInput2', 'secAnswerInput3'), // this validator is used to check for duplicate answers
    ]),
    secReEnterInput1: new FormControl('', [
      Validators.required,
      confirmationValidator('secAnswerInput1'),
    ]),
    secAnswerInput2: new FormControl('', [
      Validators.required,
      Validators.maxLength(50),
      Validators.pattern(this.validPattern),
      sameUsernameValidator(this.session.userName),
      sameAnswerValidator('secAnswerInput1', 'secAnswerInput3'),
    ]),
    secReEnterInput2: new FormControl('', [
      Validators.required,
      confirmationValidator('secAnswerInput2'),
    ]),
    secAnswerInput3: new FormControl('', [
      Validators.required,
      Validators.maxLength(50),
      Validators.pattern(this.validPattern),
      sameUsernameValidator(this.session.userName),
      sameAnswerValidator('secAnswerInput1', 'secAnswerInput2'),
    ]),
    secReEnterInput3: new FormControl('', [
      Validators.required,
      confirmationValidator('secAnswerInput3'),
    ]),
    securityQuestionsForm: [this.securityQuestionsForm, Validators.required],
    securityQuestionsForm1: this.securityQuestionsForm1,
    securityQuestionsForm2: this.securityQuestionsForm2,
    securityQuestionsForm3: this.securityQuestionsForm3,
  })

  ngOnInit(): void {
    this.adobeData = {
      section: environment.LOGON_ADOBE_SECTION,
      subSection: environment.LOGON_ADOBE_SUBSECTION,
      pageId: environment.REG_TC_ADOBE_PAGEID,
    }
    this.content = this.contentService.caasContent
    this.userName = this.session.userName
    this.transmitEnabledUser = this.session.transmitEnabled
    this.registerUrl =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_WEBSERVICE_BASE + environment.USER_REGISTRATION_POSTFIX
    this.transmitEmailNotificationUrl =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_WEBSERVICE_BASE +
      environment.TRANSMIT_EMAIL_NOTIFICATION_ENDPOINT
    this.cancelRestEndPoint =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_WEBSERVICE_BASE + environment.CANCEL_REST_POSTFIX
    this.headerText.setComponent(
      this.content.Data.registrationPage.content.titleId,
    )
    this.headerText.setHeader(
      this.content.Data.registrationPage.content.headerText,
    )
    this.headerText.setShowHeadText(false)
    this.adobeAnalytics.updateData(this.adobeData)
    this.adobeAnalytics.track()

    this.retrieveTicketForUser()

    this.newPasswordInstruction = [
      this.content.Data.registrationPage.content.registerAccess.registerAccess
        .registrationForm.registrationForm.newPasswordText.newPasswordText
        .characterText +
        ' ' +
        this.content.Data.registrationPage.content.registerAccess.registerAccess
          .registrationForm.registrationForm.newPasswordText.newPasswordText
          .caseText,
    ]
    this.newUsernameInstruction = [
      this.content.Data.registrationPage.content.registerAccess.registerAccess
        .registrationForm.registrationForm.newUserNameText,
    ]

    this.registrationForm = this.fb.group({
      tempPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20),
        twoAlphaTwoNumValidator(),
      ]),
      newUsername: new FormControl('', [
        Validators.required,
        Validators.pattern(environment.USERNAME_REGEX),
        Validators.minLength(6),
        Validators.maxLength(12),
        sameUsernameValidator(this.userName),
      ]),
      newPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20),
        twoAlphaTwoNumValidator(),
        identicalControlValidator('tempPassword'),
        usernamePwdMatchValidator('newUsername'),
        sameUsernameValidator(this.userName),
        usernameOrPasswordStrValidator(),
      ]),
      reenterNewPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20),
        confirmationValidator('newPassword'),
      ]),
    })
  }

  retrieveTicketForUser() {
    this.transmitTicketPayload =
      this.ticketHelperService.buildTransmitTicketPayload(
        'SMS_TEXT',
        ENROLL_TRANSACTION_TYPE,
        '1111111111',
        true,
        this.session.userName,
        Transmit.RETRIEVE_QUESTIONS,
      )

    this.loggerService.info(
      'Retrieving the ticket for QA Enrollment in Registration component for Poid: ' +
        this.session.poid,
    )
    const ticketServiceResponse = this.ticketService.getTransmitTicket(
      TRANSMIT_TICKET_URL,
      this.transmitTicketPayload,
    )

    ticketServiceResponse.subscribe({
      next: (data: TransmitTicketResponse) => {
        this.session.transmitTicket = data.transmitTicket
        this.session.boundDevices = data.boundDevices
        this.loggerService.info(
          'Got the ticket for QA enrollment journey in Registration component for Poid: ' +
            this.session.poid +
            ' ticket: ' +
            data.transmitTicket,
        )
        this.getSecurityQNA()
      },

      error: (error) => {
        this.loggerService.error(
          '******Error occured while retrieving transmit ticket for QA enrollment in Registration component : Status: ' +
            error.status +
            ', error: ' +
            error.message +
            ' for Poid: ' +
            this.session.poid,
        )
        this.ticketResponse = {
          transmitTicket: '',
          boundDevices: 0,
        }
        this.loading = false
        this.errorMsgService.invalidMessage = 'serviceFailureLogon'
        this.warningBanner.setWarningBannerMessage('serviceFailureLogon')
      },
    })
  }

  nextStep() {
    this.stepTwo()
  }

  checkValidation(): boolean {
    if (this.stepOneView) {
      if (this.registrationForm.invalid) {
        return true
      } else {
        return false
      }
    } else if (this.stepTwoView) {
      if (this.securityQNAForm.invalid) {
        return true
      } else {
        return false
      }
    }
  }

  toggle() {
    this.disableForm = !this.disableForm
    if (this.disableForm) {
      this.registrationForm.controls.tempPassword.disable()
      this.registrationForm.controls.newUsername.disable()
      this.registrationForm.controls.newPassword.disable()
      this.registrationForm.controls.reenterNewPassword.disable()

      this.securityQNAForm.controls.secAnswerInput1.disable()
      this.securityQNAForm.controls.secReEnterInput1.disable()
      this.securityQNAForm.controls.secAnswerInput2.disable()
      this.securityQNAForm.controls.secReEnterInput2.disable()
      this.securityQNAForm.controls.secAnswerInput3.disable()
      this.securityQNAForm.controls.secReEnterInput3.disable()
    } else {
      this.registrationForm.controls.tempPassword.enable()
      this.registrationForm.controls.newUsername.enable()
      this.registrationForm.controls.newPassword.enable()
      this.registrationForm.controls.reenterNewPassword.enable()

      this.securityQNAForm.controls.secAnswerInput1.enable()
      this.securityQNAForm.controls.secReEnterInput1.enable()
      this.securityQNAForm.controls.secAnswerInput2.enable()
      this.securityQNAForm.controls.secReEnterInput2.enable()
      this.securityQNAForm.controls.secAnswerInput3.enable()
      this.securityQNAForm.controls.secReEnterInput3.enable()
      this.securityQNAForm.controls['secAnswerInput1'].setValidators([
        sameUsernameValidator(this.session.userName),
        sameAnswerValidator('secAnswerInput2', 'secAnswerInput3'), // this validator is used to check for duplicate answers
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(this.validPattern),
      ])
      this.securityQNAForm.controls['secAnswerInput2'].setValidators([
        sameUsernameValidator(this.session.userName),
        sameAnswerValidator('secAnswerInput1', 'secAnswerInput3'), // this validator is used to check for duplicate answers
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(this.validPattern),
      ])
      this.securityQNAForm.controls['secAnswerInput3'].setValidators([
        sameUsernameValidator(this.session.userName),
        sameAnswerValidator('secAnswerInput1', 'secAnswerInput2'), // this validator is used to check for duplicate answers
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(this.validPattern),
      ])
    }
  }

  register() {
    if (this.stepOneView) {
      this.registrationForm.markAllAsTouched()
      this.allFieldValidator()
      if (!this.registrationForm.invalid) {
        this.registerPayload = {
          password: this.registrationForm.value.tempPassword,
          newPassword: this.registrationForm.value.newPassword,
          newUserName: this.registrationForm.value.newUsername,
        }
        this.loading = true
        this.invalidMessage = ''
        this.warningBanner.setWarningBannerMessage('')
        this.toggle()
        this.registerService
          .registerCredentials(this.registerUrl, this.registerPayload)
          .subscribe(
            (data: RegisterResponse) => {
              this.checkRegistrationState(data)
              this.loading = false
            },
            (error) => {
              this.loggerService.error(
                'Error occured while sending new permanent password from registration component: Status: ' +
                  error.status +
                  ', error: ' +
                  error.message +
                  ' for Poid: ' +
                  this.session.poid,
              )
              this.registrationForm.reset()
              this.invalidMessage = 'serviceFailureLogon'
              this.warningBanner.setWarningBannerMessage('serviceFailureLogon')
              this.toggle()
              this.loading = false
            },
          )
      } else {
        this.registrationForm.markAllAsTouched()
        this.registrationForm.updateValueAndValidity({
          onlySelf: true,
          emitEvent: true,
        })
      }
    } else if (this.stepTwoView) {
      this.securityQNAForm.markAllAsTouched()
      this.allFieldValidatorsecurityQNA()
      if (!this.securityQNAForm.invalid) {
        this.saveTransmitQA()
      } else {
        this.securityQNAForm.markAllAsTouched()
        this.securityQNAForm.updateValueAndValidity({
          onlySelf: true,
          emitEvent: true,
        })
      }
    }
  }

  saveTransmitQA = async () => {
    try {
      await CommonUIhandler.prototype.setUIHandler(
        environment[this.regionService.envType][this.regionService.userType]
          .TRANSMIT_URL,
        'webenrollment',
        '',
        '',
        {
          username: this.session.poid,
          policyId: Transmit.QA_ENROLLMENT,
          additionalParams: {
            ticket: this.session.transmitTicket,
            Q1: this.answerId1,
            Q2: this.answerId2,
            Q3: this.answerId3,
            A1: this.securityQNAForm.value.secAnswerInput1,
            A2: this.securityQNAForm.value.secAnswerInput2,
            A3: this.securityQNAForm.value.secAnswerInput3,
            devicePrint: this.session.devicePrint,
            username: this.userName,
          },
          clientContext: {},
        },
      )

      const token = await ReturnToken.prototype.retrieveQuestions()

      const decodedToken = jose.decodeJwt(token)
      if (decodedToken['qaEnrollment']['status'] === 200) {
        this.loggerService.info(
          'QA Enrollment successful for Poid: ' + this.session.poid,
        )
        this.securityQNAForm.reset()
        this.jwtValidate(token)
        let resp: SecurityAnswersResponse
        if (this.session.oobBypass) {
          resp = {
            state: environment.SUCCESSFULL,
            redirectUrl: this.session.redirectUrl,
          }
        } else {
          resp = {
            state: environment.OUT_OF_BAND,
            authAction: environment.OOB_ENROLL,
          }
        }
        //Clear any banner errors, so they don't display on the next page
        this.loading = true
        this.invalidMessage = ''
        this.warningBanner.setWarningBannerMessage('')
        this.authActionState(resp) //redirect to next page
        this.sendEmail()
      } else {
        this.loggerService.error(
          'Error saving questions for Poid: ' + this.session.poid,
        )
        this.invalidMessage = 'transmitErrors'
        this.warningBanner.setWarningBannerMessage('transmitErrors')
        this.loading = false

        const numofErrors = decodedToken['qaEnrollment']['body'].length
        for (let index = 0; index < numofErrors; index++) {
          this.loggerService.error(
            'Error is in answer : ' +
              decodedToken['qaEnrollment']['body'][index]['field'] +
              ' for Poid: ' +
              this.session.poid,
          )
          this.loggerService.error(
            'Error message is : ' +
              decodedToken['qaEnrollment']['body'][index]['errorMessage'] +
              ' for Poid: ' +
              this.session.poid,
          )
          this.loggerService.error(
            'Error code is : ' +
              decodedToken['qaEnrollment']['body'][index]['errorCode'] +
              ' for Poid: ' +
              this.session.poid,
          )
        }
      }
    } catch (error: unknown) {
      this.loggerService.error(
        'An error has occured in calling the CommonUIhandler ' +
          error +
          ' for Poid: ' +
          this.session.poid,
      )
      this.securityQNAForm.reset()
      this.invalidMessage = 'serviceFailureLogon'
      this.warningBanner.setWarningBannerMessage('serviceFailureLogon')
      this.toggle()
      this.loading = false
    }
  }

  async jwtValidate(token: string) {
    const transmitJwtPayload: TransmitJWTPayLoad = {
      poid: this.session.poid,
      policyId: 'enrollQuestions',
      token: token,
      environment: this.regionService.angular_env,
    }

    const jwtValidResponse =
      this.transmitJWTValidationService.validateJWT(transmitJwtPayload)

    jwtValidResponse.subscribe({
      next: (data: any) => {
        if (data.response === 'Token Verified') {
          this.loggerService.info(
            '-- TOKEN VERIFIED --' + ' for Poid: ' + this.session.poid,
          )
        } else {
          this.loggerService.info(
            '-- TOKEN NOT VERIFIED --' + ' for Poid: ' + this.session.poid,
          )
        }
      },
      error: (error) => {
        this.loggerService.error('JWT Validation Error : ' + error) +
          ' for Poid: ' +
          this.session.poid
      },
    })
  }

  sendEmail() {
    this.transmitEmailService
      .sendEmail(
        this.transmitEmailNotificationUrl,
        EmailTemplate.SECURITY_QUESTION_UPDATE_TEMPLATE,
        Number(this.session.poid),
      )
      .subscribe({
        next: () => {
          this.loggerService.info(
            'successfully sent the email for Poid: ' + this.session.poid,
          )
        },
        error: (error) => {
          this.loggerService.error(
            'Error occured while sending email to user: Status: ' +
              error.status +
              ' for Poid: ' +
              this.session.poid,
          )
        },
      })
  }

  // REGISTRATION STEP 1
  stepOne() {
    if (this.checkedTC) {
      this.headerText.setComponent(
        this.content.Data.registrationPage.content.registerAccess.registerAccess
          .titleId,
      )
      this.headerText.setHeader(
        this.content.Data.registrationPage.content.registerAccess.registerAccess
          .header,
      )
      this.headerText.setShowHeadText(true)
      this.headerText.setHeadText(
        this.content.Data.registrationPage.content.registerAccess.registerAccess
          .headerText,
      )
      this.adobeData.pageId = environment.REG_CRED_ADOBE_PAGEID
      this.adobeAnalytics.updateData(this.adobeData)
      this.adobeAnalytics.track()
      this.tcView = false
      this.stepContainerView = false
      this.stepOneView = true
      this.invalidMessage = ''
      this.warningBanner.setWarningBannerMessage('')
      this.reviewTC = false
      window.setTimeout(
        () => document.getElementById('tempPasswordInput').focus(),
        0,
      )
    } else {
      this.reviewTC = true
    }
  }

  private checkRegistrationState(registerResponse: RegisterResponse): void {
    switch (registerResponse.state) {
      case environment.SUCCESSFULL:
      case environment.OUT_OF_BAND_TRANSMIT:
        this.invalidMessage = ''
        this.warningBanner.setWarningBannerMessage('')
        this.userName = this.registrationForm.value.newUsername
        this.session.userName = this.registrationForm.value.newUsername
        this.nextStep()
        this.registrationForm.reset()
        this.toggle()
        break
      case environment.USERNAME_AND_PASSWORD:
        this.viewService.setView(environment.USERNAME_AND_PASSWORD)
        this.toggle()
        break
      case environment.UNSUCCESSFULL:
        switch (registerResponse.authAction) {
          case environment.PASSWORD_LOCKEDOUT:
            this.errorMsgService.invalidMessage = 'locked'
            this.clear()
            break
          case environment.INVALID_LOGON_CREDENTIALS:
            this.invalidMessage = 'invalidCred'
            this.warningBanner.setWarningBannerMessage('invalidCred')
            this.registrationForm.controls.tempPassword.reset()
            break
          case environment.USERNAME_NA:
            this.invalidMessage = 'duplicateUsername'
            this.warningBanner.setWarningBannerMessage('duplicateUsername')
            break
          case environment.GENERIC_ERROR:
            this.registrationForm.reset()
            this.invalidMessage = 'serviceFailureLogon'
            this.warningBanner.setWarningBannerMessage('serviceFailureLogon')
            break
          default:
            this.registrationForm.reset()
            this.invalidMessage = 'serviceFailureLogon'
            this.warningBanner.setWarningBannerMessage('serviceFailureLogon')
            break
        }
        this.toggle()
        break
      default:
        this.toggle()
        break
    }
  }

  clear() {
    this.session.clear()
    this.logonService.cancel(this.cancelRestEndPoint).subscribe(
      (data: CancelResponse) => {
        this.viewService.setView(environment.USERNAME_AND_PASSWORD)
      },
      (error) => {
        this.loggerService.error(
          'Error occured while clearing session from Registration Step 1: Status: ' +
            error.status +
            ', error: ' +
            error.message +
            ' for Poid: ' +
            this.session.poid,
        )
        this.viewService.setView(environment.USERNAME_AND_PASSWORD)
      },
    )
  }

  onCancel() {
    this.session.clear()
    this.logonService.cancel(this.cancelRestEndPoint).subscribe(
      (data: CancelResponse) => {
        this.viewService.setView(environment.USERNAME_AND_PASSWORD)
      },
      (error) => {
        this.loggerService.error(
          'Error occured while canceling Q&A from challenge component: Status: ' +
            error.status +
            ', error: ' +
            error.message +
            ' for Poid: ' +
            this.session.poid,
        )
        this.viewService.setView(environment.USERNAME_AND_PASSWORD)
      },
    )
  }

  // REGISTRATION STEP 2
  stepTwo() {
    this.stepOneView = false
    this.stepTwoView = true
    this.headerText.setHeader(
      this.content.Data.registrationPage.content.registerAccess.registerAccess
        .questionAndAnswerSubTitle,
    )
    this.headerText.setShowHeadText(false)
    window.setTimeout(
      () => document.getElementById('tipsContentLink').focus(),
      0,
    )
    this.adobeData.pageId = environment.REG_QA_ADOBE_PAGEID
    this.adobeAnalytics.updateData(this.adobeData)
    this.adobeAnalytics.track()
  }

  getSecurityQNA(): void {
    this.getQuestionsfromTransmit()
      .then((resp) => {
        const transmitSecQuestions: SecurityQuestionsResponse = {
          questionGroup: [
            {
              challengeQuestion: resp['questionGroup_1'],
              groupName: 'question1',
              retired: false,
            },
            {
              challengeQuestion: resp['questionGroup_2'],
              groupName: 'question2',
              retired: false,
            },
            {
              challengeQuestion: resp['questionGroup_3'],
              groupName: 'question3',
              retired: false,
            },
          ],
        }
        this.securityQuestions = transmitSecQuestions.questionGroup
        this.setQuestions()
      })
      .catch((error) => {
        this.loggerService.error(
          'Error occured while getting security questions from registration component for a transmit user: Status: ' +
            error.status +
            ', error: ' +
            error.message +
            ' for Poid: ' +
            this.session.poid,
        )
      })
  }

  setQuestions() {
    this.selected1 = this.securityQuestions[0].challengeQuestion[0].questionText
    this.selected2 = this.securityQuestions[1].challengeQuestion[0].questionText
    this.selected3 = this.securityQuestions[2].challengeQuestion[0].questionText
    this.answerId1 = this.securityQuestions[0].challengeQuestion[0].questionId
    this.answerId2 = this.securityQuestions[1].challengeQuestion[0].questionId
    this.answerId3 = this.securityQuestions[2].challengeQuestion[0].questionId
    this.securityQuestionsForm[0].setValue(
      this.securityQuestions[0].challengeQuestion[0],
    )
    this.securityQuestionsForm[1].setValue(
      this.securityQuestions[1].challengeQuestion[0],
    )
    this.securityQuestionsForm[2].setValue(
      this.securityQuestions[2].challengeQuestion[0],
    )
    this.securityQuestions1 = this.securityQuestions[0].challengeQuestion
    this.securityQuestions2 = this.securityQuestions[1].challengeQuestion
    this.securityQuestions3 = this.securityQuestions[2].challengeQuestion
  }

  getQuestionsfromTransmit = async () => {
    localStorage.clear() // to clear the storage in the browser
    await CommonUIhandler.prototype.setUIHandler(
      environment[this.regionService.envType][this.regionService.userType]
        .TRANSMIT_URL,
      'webenrollment',
      '',
      '',
      {
        username: this.userName,
        policyId: Transmit.RETRIEVE_QUESTIONS,
        additionalParams: { ticket: this.session.transmitTicket },
        clientContext: {},
      },
    )
    const q: any = GetQuestions.prototype.retrieveQuestions()
    return q
  }

  private authActionState(
    securityAnswerReponse: SecurityAnswersResponse,
  ): void {
    switch (securityAnswerReponse.state) {
      case environment.SUCCESSFULL:
        this.windowRef.nativeWindow.location.href =
          securityAnswerReponse.redirectUrl
        break
      case environment.OUT_OF_BAND:
        switch (securityAnswerReponse.authAction) {
          case environment.OOB_ENROLL:
            this.viewService.setView(environment.OUT_OF_BAND)
            this.loading = false
            this.toggle()
            break
          default:
            this.viewService.setView(environment.USERNAME_AND_PASSWORD)
            this.loading = false
            this.toggle()
            break
        }
        this.toggle()
        this.loading = false
        break
      default:
        this.toggle()
        this.loading = false
        break
    }
  }

  setSelected(selection: SelectState, questionListIndex: number) {
    let question = ''
    question = selection.label

    if (questionListIndex === 1) {
      this.selected1 = question
      this.answerId1 =
        this.securityQuestions1[selection.selectedIndex].questionId
    } else if (questionListIndex === 2) {
      this.selected2 = question
      this.answerId2 =
        this.securityQuestions2[selection.selectedIndex].questionId
    } else if (questionListIndex === 3) {
      this.selected3 = question
      this.answerId3 =
        this.securityQuestions3[selection.selectedIndex].questionId
    }
  }

  setFocus(questionListIndex: number) {
    const menuItems = from(
      document
        .querySelector('.mat-menu-panel')
        .getElementsByClassName('mat-menu-item'),
    )
    setTimeout(() => {
      const result = menuItems.pipe(
        find((element: HTMLElement) => {
          if (questionListIndex === 1) {
            return element.innerText.trim() === this.selected1
          } else if (questionListIndex === 2) {
            return element.innerText.trim() === this.selected2
          } else if (questionListIndex === 3) {
            return element.innerText.trim() === this.selected3
          }
        }),
      )
      result.subscribe((x) => x && x.focus())
    }, 0)
  }

  allFieldValidator() {
    Object.keys(this.registrationForm.controls).forEach((ctrlName) => {
      this.fieldValidator(ctrlName)
    })
  }

  allFieldValidatorsecurityQNA() {
    Object.keys(this.securityQNAForm.controls).forEach((ctrlName) => {
      this.fieldValidatorsecurityQNA(ctrlName)
    })
  }

  fieldValidator(ctrlName: string) {
    const controller = this.registrationForm.get(ctrlName)
    controller.updateValueAndValidity()
    if (controller.errors) {
      if (!controller.touched && !controller.errors['required']) {
        controller.markAsTouched()
      }
    }

    switch (ctrlName.toLowerCase()) {
      case environment.TEMPPASSWORD:
        if (
          this.registrationForm.controls.tempPassword.touched &&
          this.registrationForm.controls.tempPassword.errors !== null
        ) {
          this.tempPasswordErrorMsg = this.validatorService.getValidationMsg(
            controller,
            ctrlName,
            0,
          )
        } else {
          this.tempPasswordErrorMsg = ''
        }
        break
      case environment.NEW_USERNAME:
        if (
          this.registrationForm.controls.newUsername.touched &&
          this.registrationForm.controls.newUsername.errors !== null
        ) {
          this.newUsernameErrorMsg = this.validatorService.getValidationMsg(
            controller,
            ctrlName,
            0,
          )
        } else {
          this.newUsernameErrorMsg = ''
        }
        break
      case environment.NEW_PASSWORD:
        if (
          this.registrationForm.controls.newPassword.touched &&
          this.registrationForm.controls.newPassword.errors !== null
        ) {
          this.newPasswordErrorMsg = this.validatorService.getValidationMsg(
            controller,
            ctrlName,
            0,
          )
        } else {
          this.newPasswordErrorMsg = ''
        }
        break
      case environment.REENTERPASSWORD:
        if (
          this.registrationForm.controls.reenterNewPassword.touched &&
          this.registrationForm.controls.reenterNewPassword.errors !== null
        ) {
          this.reenterNewPasswordErrorMsg =
            this.validatorService.getValidationMsg(controller, ctrlName, 0)
        } else {
          this.reenterNewPasswordErrorMsg = ''
        }
        break
      default:
        break
    }
  }

  fieldValidatorsecurityQNA(ctrlName: string) {
    const controller = this.securityQNAForm.get(ctrlName)
    controller.updateValueAndValidity()
    if (controller.errors) {
      if (!controller.touched && !controller.errors['required']) {
        controller.markAsTouched()
      }
    }

    switch (ctrlName.toLowerCase()) {
      case environment.SECURITY_ANSWER_INPUT_1:
        if (
          this.securityQNAForm.controls.secAnswerInput1.touched &&
          this.securityQNAForm.controls.secAnswerInput1.errors !== null
        ) {
          this.secAnswer1ErrorMsg = this.validatorService.getValidationMsg(
            controller,
            environment.SECURITY_QNA,
            0,
          )
        } else {
          this.secAnswer1ErrorMsg = ''
        }
        break
      case environment.SECURITY_ANSWER_REENTER_INPUT_1:
        if (
          this.securityQNAForm.controls.secReEnterInput1.touched &&
          this.securityQNAForm.controls.secReEnterInput1.errors !== null
        ) {
          this.secReEnter1ErrorMsg = this.validatorService.getValidationMsg(
            controller,
            environment.SECURITY_QNA,
            0,
          )
        } else {
          this.secReEnter1ErrorMsg = ''
        }
        break
      case environment.SECURITY_ANSWER_INPUT_2:
        if (
          this.securityQNAForm.controls.secAnswerInput2.touched &&
          this.securityQNAForm.controls.secAnswerInput2.errors !== null
        ) {
          this.secAnswer2ErrorMsg = this.validatorService.getValidationMsg(
            controller,
            environment.SECURITY_QNA,
            0,
          )
        } else {
          this.secAnswer2ErrorMsg = ''
        }
        break
      case environment.SECURITY_ANSWER_REENTER_INPUT_2:
        if (
          this.securityQNAForm.controls.secReEnterInput2.touched &&
          this.securityQNAForm.controls.secReEnterInput2.errors !== null
        ) {
          this.secReEnter2ErrorMsg = this.validatorService.getValidationMsg(
            controller,
            environment.SECURITY_QNA,
            0,
          )
        } else {
          this.secReEnter2ErrorMsg = ''
        }
        break
      case environment.SECURITY_ANSWER_INPUT_3:
        if (
          this.securityQNAForm.controls.secAnswerInput3.touched &&
          this.securityQNAForm.controls.secAnswerInput3.errors !== null
        ) {
          this.secAnswer3ErrorMsg = this.validatorService.getValidationMsg(
            controller,
            environment.SECURITY_QNA,
            0,
          )
        } else {
          this.secAnswer3ErrorMsg = ''
        }
        break
      case environment.SECURITY_ANSWER_REENTER_INPUT_3:
        if (
          this.securityQNAForm.controls.secReEnterInput3.touched &&
          this.securityQNAForm.controls.secReEnterInput3.errors !== null
        ) {
          this.secReEnter3ErrorMsg = this.validatorService.getValidationMsg(
            controller,
            environment.SECURITY_QNA,
            0,
          )
        } else {
          this.secReEnter3ErrorMsg = ''
        }
        break
      default:
        break
    }
  }
  openTipsLayer() {
    this.vuiDialog.openDialog(
      this.viewContainer,
      this.tipsContentDialog,
      this.vuiDialogConfig,
    )
  }

  onInput(value) {
    const enterAnswerId = 'secAnswerInput' + value
    const enterAnswerInputId = 'secAnswerInput' + value + 'Input'
    const reEnterAnswerId = 'secReEnterInput' + value
    const reEnterAnswerInputId = 'secReEnterInput' + value + 'Input'
    this.verifyValidationIcon(enterAnswerId, enterAnswerInputId)
    this.verifyValidationIcon(reEnterAnswerId, reEnterAnswerInputId)
  }

  onInputReEnter(value) {
    const reEnterAnswerInputId = 'secReEnterInput' + value + 'Input'
    const reEnterAnswerId = 'secReEnterInput' + value
    this.verifyValidationIcon(reEnterAnswerId, reEnterAnswerInputId)
  }

  verifyValidationIcon(inputId, id) {
    if (this.securityQNAForm.controls[inputId]?.valid) {
      document
        .getElementById(id)
        .parentElement.getElementsByClassName(vuiInvalid)[0]
        .setAttribute('hidden', 'true')
      document
        .getElementById(id)
        .parentElement.getElementsByClassName(vuiValid)[0]
        .removeAttribute('hidden')
    }
    if (
      this.securityQNAForm.controls[inputId]?.touched &&
      this.securityQNAForm.controls[inputId]?.invalid
    ) {
      document
        .getElementById(id)
        .parentElement.getElementsByClassName(vuiValid)[0]
        .setAttribute('hidden', 'true')
      document
        .getElementById(id)
        .parentElement.getElementsByClassName(vuiInvalid)[0]
        .removeAttribute('hidden')
    }
  }
  onInputPW() {
    const enterPassword = 'newPassword'
    const enterPasswordInputId = 'newPasswordInput'
    const reEnterPasswordId = 'reenterNewPassword'
    const reEnterPasswordInputId = 'reenterNewPasswordInput'
    this.verifyValidationIconInRegisterAccess(
      enterPassword,
      enterPasswordInputId,
    )
    this.verifyValidationIconInRegisterAccess(
      reEnterPasswordId,
      reEnterPasswordInputId,
    )
  }

  onInputReEnterPW() {
    const reEnterPasswordId = 'reenterNewPassword'
    const reEnterPasswordInputId = 'reenterNewPasswordInput'
    this.verifyValidationIconInRegisterAccess(
      reEnterPasswordId,
      reEnterPasswordInputId,
    )
  }

  verifyValidationIconInRegisterAccess(inputId, id) {
    this.registrationForm.get(inputId).updateValueAndValidity()
    if (this.registrationForm.controls[inputId]?.valid) {
      document
        .getElementById(id)
        .parentElement.getElementsByClassName(vuiInvalid)[0]
        .setAttribute('hidden', 'true')
      document
        .getElementById(id)
        .parentElement.getElementsByClassName(vuiValid)[0]
        .removeAttribute('hidden')
    }
    if (
      this.registrationForm.controls[inputId]?.touched &&
      this.registrationForm.controls[inputId]?.invalid
    ) {
      document
        .getElementById(id)
        .parentElement.getElementsByClassName(vuiValid)[0]
        .setAttribute('hidden', 'true')
      document
        .getElementById(id)
        .parentElement.getElementsByClassName(vuiInvalid)[0]
        .removeAttribute('hidden')
    }
  }
  hidePw = true
  inputTypePw = this.hidePw ? 'password' : 'text'
  handleShowHidePwBtn(): void {
    this.hidePw = !this.hidePw
    this.inputTypePw = this.hidePw ? 'password' : 'text'
  }

  hideNpw = true
  inputTypeNpw = this.hideNpw ? 'password' : 'text'

  handleShowHideNpwBtn(): void {
    this.hideNpw = !this.hideNpw
    this.inputTypeNpw = this.hideNpw ? 'password' : 'text'
  }

  hideRnpw = true
  inputTypeRnpw = this.hideRnpw ? 'password' : 'text'
  handleShowHideRnpwBtn(): void {
    this.hideRnpw = !this.hideRnpw
    this.inputTypeRnpw = this.hideRnpw ? 'password' : 'text'
  }
}
