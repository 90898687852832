import {
  Component,
  OnInit,
  Renderer2,
  Input,
  TemplateRef,
  AfterViewChecked,
  ViewChild,
} from '@angular/core'
import { HeaderTextService } from '@app/shared/services/headerTextService'
import { environment } from '@env'
import { ContentService } from '@app/shared/services/contentService'
import {
  FormGroup,
  FormBuilder,
  FormControl,
  UntypedFormControl,
  Validators,
} from '@angular/forms'
import { CancelResponse } from '../models/CancelResponse'
import { Logon } from '@app/shared/services/logonService'
import { RegionService } from '@app/shared/services/regionService'
import { AdobeAnalyticsService } from '../shared/services/adobe-analytics/adobe-analytics.service'
import { ErrorMsgService } from '@app/shared/services/errorMsgService'
import { numOfDigitsValidator } from '@app/shared/validators/numOfDigitsValidator'
import { ResendCodePayload } from '@app/models/ResendCodePayload'
import { OtpChallengePayload } from '../models/OtpChallengePayload'
import { OtpEnrollmentPayload } from '../models/OtpEnrollmentPayload'
import { ServiceResponse } from '../models/ServiceResponse'
import { StatusResponse } from '@app/models/StatusResponse'
import { ProcessResponseService } from '@app/shared/services/processResponseService'
import { CountryCode } from '@app/models/CountryCode'
import { WarningBannerService } from '@app/shared/services/warningBannerService'
import { AdobeLaunchConfigurationService } from '@app/shared/services/adobe-launch-configuration/adobeLaunchConfiguration.service'
import { VieFormsRedirectService } from '@app/shared/services/vieFormsRedirect/vieFormsRedirect.service'
import { SessionService } from '@app/shared/services/sessionService'
import { TransmitTicketPayload } from '@app/models/TransmitTicketPayload'
import {
  Transmit,
  TransmitAuthenticateResult,
  TRANSMIT_TICKET_URL,
  NOT_APPLICABLE,
  POLICY_ID,
  TICKET_APP_ID,
  JOURNEY_ID,
  ACCOUNT_ID,
  SERVICE_ID,
  ENROLL_TRANSACTION_TYPE,
  OOB_CHALLENGE_TYPE,
  OOB_ENROLL,
  TransmitErrorCode,
} from '@app/models/TransmitConstants'
import { TransmitTicketService } from '@app/shared/services/transmitCommonService/transmitTicket.service'
import { TransmitTicketResponse } from '@app/models/TransmitTicketResponse'
import { EmailTemplate } from '@app/models/EmailTemplate'
import { TransmitOtpService } from '@app/shared/services/transmitOtpService/transmitOtp.service'
import { setUiHandler } from '@app/shared/services/transmitEnrollOtpService/transmitEnrollOtpUiHandler'
import { TransmitSdkService } from '@app/shared/services/transmitCommonService/transmitSdk.service'
import { prototype } from 'events'
import { ViewService } from '@app/shared/services/viewService'
import { XmSdk } from '@vanguard/transmit/src/xm/js/xmsdk.js'
import { LoggerService } from '@app/shared/services/loggerService'
import { SelectState } from '@vg-constellation/angular-15/select'

const ariaDisabled = 'aria-disabled'
const accountMaintenance = 'account-maintenance%2F'
const sdk = XmSdk()

@Component({
  selector: 'forced-oob-enrollment-view',
  templateUrl: './forcedOobEnrollment.component.html',
  styleUrls: ['./forcedOobEnrollment.component.scss'],
})
export class ForcedOobEnrollmentComponent implements OnInit, AfterViewChecked {
  @ViewChild('modalDialog') modalDialog

  checkedTC = false
  reviewTC = false
  cancelResponse: CancelResponse
  content: any
  oobEnrollmentForm: FormGroup
  oobOtpChallengeForm: FormGroup
  continueBtnDisabled = true
  cancelRestEndPoint: string
  disableForm = false
  validationCodeMsgText: string[]
  EM_DASH: string
  adobeData: { section: string; subSection: string; pageId: string }
  hideRememberMe = true
  otpEnrollmentUrl: string
  otpChallengePayload: OtpChallengePayload
  otpEnrollmentPayload: OtpEnrollmentPayload
  serviceResponse: ServiceResponse
  loading: boolean
  popUpOtpContent: string
  invalidMessage = ''
  invalidOTPMessage = ''
  resendCodePayload: ResendCodePayload
  showPopUpMsg: boolean
  popUpMsg: string
  otpResponse: StatusResponse
  selectedCountry: CountryCode = null
  listOfInternationalPhoneCodesUrl: string
  listOfInternationalPhoneCodes: CountryCode[]
  isInternationalPhone: boolean
  selectedCountryCode: number
  parsedPhoneNumber: string
  parsedAreaCode: string
  maximumPhoneDigits = 12
  borderElement: any
  inputElement: any
  borderElementCountry: any
  charLimit = 12
  errorBanner = false
  maxSessionsReached = false
  disableLink = false
  contactMethodRadio: FormControl = new FormControl(environment.TEXT_OPTION)
  frequencyRadio: FormControl = new FormControl(environment.FREQ_ALWAYS)
  phoneNumberError = 'phoneNumberError'
  formUrl: string
  transmitEnabledUser = false
  private transmitTicketPayload: TransmitTicketPayload
  public phone: string
  public channel: string
  ticketResponse: TransmitTicketResponse
  counter = 0
  plusSign: string
  legacyLogon: boolean

  showErrorMessage = false

  constructor(
    private readonly logonService: Logon,
    private readonly fb: FormBuilder,
    private readonly contentService: ContentService,
    private readonly adobeAnalytics: AdobeAnalyticsService,
    private readonly regionService: RegionService,
    private readonly headerText: HeaderTextService,
    public readonly errorMsgService: ErrorMsgService,
    public readonly warningBanner: WarningBannerService,
    private readonly processResponseService: ProcessResponseService,
    private readonly renderer: Renderer2,
    private readonly adobeLaunchConfigurationService: AdobeLaunchConfigurationService,
    private readonly vieFormsRedirectService: VieFormsRedirectService,
    private session: SessionService,
    private ticketService: TransmitTicketService,
    readonly transmitOtpservice: TransmitOtpService,
    private transmitSdkService: TransmitSdkService,
    private viewService: ViewService,
    private readonly loggerService: LoggerService,
  ) {}

  ngOnInit() {
    this.legacyLogon = this.isPrettyUrl()
    this.adobeData = {
      section: environment.LOGON_ADOBE_SECTION,
      subSection: environment.LOGON_ADOBE_SUBSECTION,
      pageId: environment.OOB_CHALLENGE_ADOBE_PAGEID,
    }
    this.content = this.contentService.caasContent
    this.headerText.setComponent(
      this.content.Data.registrationPage.content.forcedOobEnrollmentLayer
        .forcedOobEnrollmentLayer.titleId,
    )
    this.headerText.setHeader(
      this.content.Data.registrationPage.content.forcedOobEnrollmentLayer
        .forcedOobEnrollmentLayer.headerText,
    )
    this.headerText.setShowHeadText(true)
    this.headerText.setHeadText(
      this.content.Data.registrationPage.content.forcedOobEnrollmentLayer
        .forcedOobEnrollmentLayer.subHeaderText,
    )
    this.cancelRestEndPoint =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_WEBSERVICE_BASE + environment.CANCEL_REST_POSTFIX
    this.otpEnrollmentUrl =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_WEBSERVICE_BASE + environment.SECURIY_CODE_OTP_ENROLLMENT
    this.validationCodeMsgText = [
      this.content.Data.oobSecurityPage.content.securityCodeForm
        .securityCodeForm.validationCodeMsgText.validationCodeMsgText.text,
      this.content.Data.oobSecurityPage.content.securityCodeForm
        .securityCodeForm.validationCodeMsgText.validationCodeMsgText.link,
    ]
    this.EM_DASH = environment.EM_DASH
    this.adobeAnalytics.updateData(this.adobeData)
    this.adobeAnalytics.track()

    this.oobEnrollmentForm = this.fb.group({
      oobCode: new FormControl('', [
        Validators.required,
        Validators.pattern(environment.ALPHA_NUM_SPECIAL_CHAR_REG_EX),
        Validators.maxLength(20),
      ]),
    })
    this.oobOtpChallengeForm = this.fb.group({
      countryCode: new FormControl('', [Validators.required]),
      phoneNumber: new FormControl('', [
        Validators.required,
        numOfDigitsValidator(this.maximumPhoneDigits, true),
        Validators.pattern(environment.PHONE_NUM_REG_EX),
      ]),
      contactMethodRadio: this.contactMethodRadio,
      frequencyRadio: this.frequencyRadio,
    })
    this.adobeLaunchConfigurationService.trackPageLoad(environment.OUT_OF_BAND)

    this.listOfInternationalPhoneCodesUrl =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_WEBSERVICE_BASE + environment.COUNTRY_PHONE_CODES
    this.getListOfInternationalCodes()
    document.getElementById('global-header').style.display = 'inline'
    document.getElementById('global-footer').style.display = 'inline'
    document.getElementById('header-text').style.display = 'inline'
    this.formUrl = window.location.href
    if (this.selectedCountryCode != null) {
      this.plusSign = '+'
    }
  }

  getListOfInternationalCodes() {
    this.loading = true
    this.logonService
      .getListOfInternationalPhoneCodes(this.listOfInternationalPhoneCodesUrl)
      .subscribe({
        next: (data) => {
          this.listOfInternationalPhoneCodes = data
          this.setUSCodeFirst(data)
          this.loading = false
          this.errorBanner = false
        },
        error: (error) => {
          this.loggerService.error(
            'Error occured while retrieving international phone codes: ' +
              'for Poid: ' +
              this.session.poid +
              ', Status: ' +
              error.status +
              ', error: ' +
              error.message,
          )
          this.errorBanner = true
          this.loading = false
        },
      })
  }
  setUSCodeFirst(countryArray) {
    // look up for United States index
    // remove from the array
    // push the element in the array at index 0
    countryArray.find((o, i) => {
      if (o.countryName === 'United States') {
        countryArray.splice(i, 1)
        countryArray.unshift(o)
      }
    })
  }

  checkIfValid() {
    if (this.oobOtpChallengeForm.status == 'VALID') {
      this.showErrorMessage = false
    } else {
      this.showErrorMessage = true
    }
  }

  onSelectCountry(selection: SelectState) {
    this.plusSign = '+'
    this.selectedCountry =
      this.listOfInternationalPhoneCodes[selection.selectedIndex - 1]
    this.oobOtpChallengeForm.controls['contactMethodRadio'].reset()
    if (this.selectedCountry === null || this.selectedCountry === undefined) {
      this.selectedCountryCode = null
      this.isInternationalPhone = true
      this.charLimit = 12
      this.oobOtpChallengeForm.controls['contactMethodRadio'].setValue(
        environment.INTL_TEXT_OPTION,
      )
      this.oobOtpChallengeForm.controls['phoneNumber'].setValidators([
        Validators.required,
        numOfDigitsValidator(12, this.isInternationalPhone),
        Validators.pattern(environment.PHONE_NUM_REG_EX),
      ])
    } else {
      if (this.selectedCountry.phoneCode !== 1) {
        this.isInternationalPhone = true
        this.charLimit = 12
        this.oobOtpChallengeForm.controls['contactMethodRadio'].setValue(
          environment.INTL_TEXT_OPTION,
        )
        this.oobOtpChallengeForm.controls['phoneNumber'].setValidators([
          Validators.required,
          numOfDigitsValidator(12, this.isInternationalPhone),
          Validators.pattern(environment.PHONE_NUM_REG_EX),
        ])
      } else {
        this.isInternationalPhone = false
        this.charLimit = 10
        this.oobOtpChallengeForm.controls['contactMethodRadio'].reset()
        this.oobOtpChallengeForm.controls['contactMethodRadio'].setValue(
          environment.TEXT_OPTION,
        )
        this.oobOtpChallengeForm.controls['phoneNumber'].setValidators([
          Validators.required,
          numOfDigitsValidator(10, this.isInternationalPhone),
          Validators.pattern(environment.PHONE_NUM_REG_EX),
        ])
      }
      this.selectedCountryCode = this.selectedCountry.phoneCode
    }
    this.oobOtpChallengeForm.updateValueAndValidity({
      onlySelf: true,
      emitEvent: true,
    })
  }

  resetForm() {
    if (this.isInternationalPhone) {
      this.oobOtpChallengeForm.controls['contactMethodRadio'].setValue(
        environment.INTL_TEXT_OPTION,
      )
    } else {
      this.oobOtpChallengeForm.controls['contactMethodRadio'].setValue(
        environment.TEXT_OPTION,
      )
    }
    this.oobOtpChallengeForm.controls['frequencyRadio'].setValue(
      environment.FREQ_ALWAYS,
    )
    this.oobOtpChallengeForm.controls['phoneNumber'].setValue('')
    this.oobOtpChallengeForm.controls['countryCode'].setValue('')
  }

  phoneNumberFormater(phoneNumber): string {
    if (this.selectedCountryCode === 1) {
      if (phoneNumber.indexOf(' ') >= 0) {
        return phoneNumber
          .split(' ')[1]
          .replace(/^(\d{3})(\d{3})(\d{4})+$/, '+1 $1 $2 $3')
      }
      return phoneNumber.replace(/^(\d{3})(\d{3})(\d{4})+$/, '+1 $1 $2 $3')
    } else {
      if (phoneNumber.indexOf(' ') >= 0) {
        return '+' + this.selectedCountryCode + ' ' + phoneNumber.split(' ')[1]
      }
      return '+' + this.selectedCountryCode + ' ' + phoneNumber
    }
  }

  clearMsgs(): void {
    this.errorMsgService.clearAll()
    this.loading = false
    this.invalidMessage = ''
    this.warningBanner.setWarningBannerMessage('')
    this.popUpMsg = ''
    this.invalidOTPMessage = ''
  }

  checkMsgs(oobEnroll: boolean): void {
    this.loading = this.errorMsgService.loading
    if (oobEnroll) {
      if (this.errorMsgService.invalidMessage === 'serviceFailureLogon') {
        this.warningBanner.setWarningBannerMessage('serviceFailureLogon')
        this.invalidMessage = 'serviceFailureLogon'
      }
    } else {
      this.warningBanner.setWarningBannerMessage(
        this.errorMsgService.invalidMessage,
      )
      this.invalidMessage = this.errorMsgService.invalidMessage
      this.popUpMsg = this.errorMsgService.popUpMsg
    }
    this.invalidOTPMessage = this.errorMsgService.invalidOTPMessage
    if (this.errorMsgService.invalidOTPMessage !== '') {
      this.popUpMsg = this.invalidOTPMessage
      this.showPopUpMsg = true
    }
  }

  otpChallenge() {
    if (!this.checkedTC) {
      this.reviewTC = true
    } else {
      this.reviewTC = false
      this.oobEnrollmentForm.reset()
      this.oobOtpChallengeForm.markAllAsTouched()
      this.checkIfValid()
      if (!this.oobOtpChallengeForm.invalid && !this.maxSessionsReached) {
        this.clearMsgs()
        this.loading = true
        this.parsedPhoneNumber = this.parsePhoneNumber()
        this.parsedAreaCode = this.parseAreaCode()

        //If this is a transmit user, call the transmit ticket service and then the OTP enrollment journey
        this.transmitEnabledUser = this.session.transmitEnabled
        this.retrieveTicketForUser()
        this.checkMsgs(true) // do we need this here???
      }
    }
  }

  async initializeTransmitJourney() {
    const transmitModel = {
      ticket: this.session.transmitTicket,
      isBoundDevice: this.session.boundDevices,
      poId: this.session.poid,
      journeyName: Transmit.OTP_ENROLLMENT,
    }

    const encryptedRetailParams = false

    const transmitParams = {
      auth1: 0,
      auth2: 0,
      devicePrint: this.session.devicePrint,
      retailParams: encryptedRetailParams,
    }

    setUiHandler(this)
    await this.transmitOtpservice
      .authenticateOtpJourney(transmitModel, transmitParams)
      .then((result: TransmitAuthenticateResult) => {
        this.loggerService.info(
          'Transmit OTP Enrollment Journey complete with result : ' +
            result +
            ' for Poid:' +
            this.session.poid,
        )
        this.adobeLaunchConfigurationService.trackOobSignUp() /// confirm this location
        if (result === TransmitAuthenticateResult.SUCCESSFUL) {
          this.loggerService.info(
            'Transmit OTP authentication journey is successful for Poid: ' +
              this.session.poid,
          )

          //The transmit journey is successful, so call IL2 to enroll the user, get the redirect url and generate the email
          this.oobTransmitEnroll()
        } else if (result === TransmitAuthenticateResult.LOCKOUT) {
          const response: ServiceResponse = {
            state: environment.UNSUCCESSFULL,
            authAction: environment.GENERIC_ERROR,
          }
          this.closeModalDialog()
          this.processResponseService.proccess(response)
        } else {
          this.loggerService.info(
            'Failed to invoke  OTP Journey Enrollment - Authentication for Poid: ' +
              this.session.poid,
          )
          if (
            this.transmitSdkService.errorCode ===
              TransmitErrorCode.ControlFlowExpired ||
            this.transmitSdkService.errorCode ===
              TransmitErrorCode.SessionRequired
          ) {
            this.closeDialog()
            this.closeModalDialog()
          }
        }
      })
      .catch((err) => {
        this.loggerService.error(
          'Error occured while calling authenticateOtpJourney, Status: ' +
            err.status +
            ', error: ' +
            err.message +
            ' for Poid: ' +
            this.session.poid,
        )
        this.errorHandling()
      })
  }

  disableFormMaxSession() {
    this.oobOtpChallengeForm.controls['countryCode'].disable()
    this.oobOtpChallengeForm.controls['phoneNumber'].disable()

    this.oobOtpChallengeForm.controls['contactMethodRadio'].disable()
    this.oobOtpChallengeForm.controls['frequencyRadio'].disable()

    // add tabIndex = -1 to prevent the 'Get more information...' and "Terms and conditions" links getting the focus when disabled
    document.getElementById('getMoreInfoLink').setAttribute('tabIndex', '-1')
    document.getElementById('oobTermsLink').setAttribute('tabIndex', '-1')

    document
      .getElementById('getMoreInfoLink')
      .setAttribute(ariaDisabled, 'true')
    document
      .getElementById('getMoreInfoLink')
      .setAttribute('class', 'sendNewCodeDisabled')

    document
      .getElementById('textOptionDomestic')
      .setAttribute(ariaDisabled, 'true')
    document.getElementById('callOption').setAttribute(ariaDisabled, 'true')

    document.getElementById('freqAlways').setAttribute(ariaDisabled, 'true')
    document.getElementById('freqUnbound').setAttribute(ariaDisabled, 'true')

    document.getElementById('termsCheckbox').setAttribute(ariaDisabled, 'true')

    /* istanbul ignore else*/
    if (!this.showPopUpMsg) {
      document.getElementById('cancelBtn').focus()
    }
  }

  disablePopUpFormMaxSession() {
    this.oobEnrollmentForm.controls['oobCode'].disable()
    this.disableLink = this.maxSessionsReached
    // add tabIndex = -1 to prevent the Send new code link getting the focus when disabled
    document.getElementById('sendNewCode').setAttribute('tabIndex', '-1')
    document.getElementById('sendNewCode').setAttribute('aria-disabled', 'true')
    document
      .getElementById('sendNewCode')
      .setAttribute('class', 'sendNewCodeDisabled')

    document.getElementById('closePopUpOtp').focus()
  }

  modifyAriaIndex(element, id) {
    /* istanbul ignore else*/
    if (element != null) {
      return element.setAttribute('aria-describedby', id)
    }
  }

  ngAfterViewChecked() {
    /* istanbul ignore else*/
    if (document.getElementById('cancelBtn') && this.maxSessionsReached) {
      this.modifyAriaIndex(
        document
          .getElementById('cancelBtn')
          .getElementsByTagName('button')
          .item(0),
        'warningBannerError',
      )
    }
  }

  sendNewOtp() {
    if (this.maxSessionsReached) {
      return
    }
    this.clearMsgs()
    this.oobEnrollmentForm.reset()
    this.loading = true
    this.processTransmitUser()
  }

  processTransmitUser() {
    if (this.counter < 4) {
      this.transmitOtpservice.selfServiceSendNewCode()
      this.popUpMsg = 'resendSuccess'
      this.showPopUpMsg = true
      this.oobEnrollmentForm.controls['oobCode'].enable()
      this.counter++
    } else if (this.counter === 4) {
      // needs to be fixed, getting an error on maxing out
      this.maxSessionsReached = true
      this.disablePopUpFormMaxSession()
      this.disableFormMaxSession()
      this.warningBanner.setWarningBannerMessage('maxSessions')
      this.loading = false
      this.popUpMsg = 'maxSessions'
      this.showPopUpMsg = true
    }
  }

  closeDialog() {
    if (this.maxSessionsReached) {
      document.getElementById('cancelBtn').focus()
    }
    //Need to cancel the current journey, get a new ticket, and start a new journey if the user wants to sign up again
    sdk.cancelCurrentRunningControlFlow()
    this.loading = false
  }

  openOtpPopUp(open: boolean) {}

  createTransmitOobEnrollPayload(): OtpEnrollmentPayload {
    const consentVersion =
      this.content.Data.oobSecurityPage.content.consentVersion
    const otpEnrollmentPayload: OtpEnrollmentPayload = {
      enrollmentDetails: {
        consentVersion: Number(consentVersion) || 1,
        challengeFrequencyCode:
          this.oobOtpChallengeForm.controls['frequencyRadio'].value,
        registeredPhones: [
          {
            international: this.isInternationalPhone,
            areaCode: this.parsedAreaCode,
            deliveryMethod:
              this.oobOtpChallengeForm.controls['contactMethodRadio'].value,
            phoneNumber: this.parsedPhoneNumber,
            countryCode: this.selectedCountryCode,
            primary: true,
          },
        ],
      },
      oneTimePasscode: this.oobEnrollmentForm.controls['oobCode'].value,
      transmitUser: true,
      userPoid: this.session.poid,
      transmitJwtData: {
        transmitPolicyId: Transmit.OTP_ENROLLMENT,
        transmitEnvironment: this.regionService.angular_env,
        transmitToken: this.transmitSdkService.token,
      },
    }
    return otpEnrollmentPayload
  }

  callEnrollmentApi() {
    const urlEnroll = this.legacyLogon
      ? environment.LEGACYLOGON + OOB_ENROLL
      : OOB_ENROLL

    this.logonService
      .otpEnrollment(urlEnroll, this.otpEnrollmentPayload)
      .subscribe({
        next: (data: StatusResponse) => {
          //VIE forms redirection
          if (
            this.formUrl.includes('account-maintenance%2F') &&
            this.processResponseService.isVieUser
          ) {
            data.redirectUrl = this.vieFormsRedirectService.vieFormUrl(
              this.formUrl,
            )
          }
          this.processResponseService.proccess(data)
          this.checkMsgs(true)
          this.oobEnrollmentForm.controls['oobCode'].reset()
          this.oobEnrollmentForm.enable()
          if (data.authAction === environment.TOKEN_NOT_VERIFIED) {
            this.loggerService.info(
              'Errored occured in calling IL2 with reason:' +
                environment.TOKEN_NOT_VERIFIED +
                'for Poid: ' +
                this.session.poid,
            )
            this.closeDialog() // this check is to handle the JWT validation in IL2 before saving the phone number
          }
        },
        error: (error) => {
          this.loggerService.error(
            'Error occured while sending user enroll form in forced OOB enrollment component for Poid: ' +
              this.session.poid +
              ', Status: ' +
              error.status +
              ', error: ' +
              error.message,
          )
          this.loading = false
          this.oobEnrollmentForm.enable()
          if (error.status === 400) {
            this.popUpMsg = 'invalidCode'
            this.showPopUpMsg = true
            this.oobEnrollmentForm.controls['oobCode'].setValue('')
          } else {
            this.popUpMsg = 'serviceFailure'
            this.showPopUpMsg = true
          }
        },
      })
  }

  oobEnroll() {
    if (this.oobEnrollmentForm.valid) {
      this.clearMsgs()
      this.loading = true
      this.oobEnrollmentForm.disable()

      //This is a transmit user, so submit the code which will get authenticated by the journey
      this.transmitOtpservice.submitOtpCode(
        this.oobEnrollmentForm.controls['oobCode'].value,
      )
    } else {
      this.oobEnrollmentForm.controls['oobCode'].markAsTouched()
      this.oobEnrollmentForm.controls['oobCode'].markAsDirty()
      this.oobEnrollmentForm.controls['oobCode'].updateValueAndValidity({
        onlySelf: false,
        emitEvent: true,
      })
    }
  }

  oobTransmitEnroll() {
    this.otpEnrollmentPayload = this.createTransmitOobEnrollPayload()
    this.callEnrollmentApi()
  }

  parsePhoneNumber() {
    if (this.isInternationalPhone) {
      let phoneInternational = ''
      if (
        this.oobOtpChallengeForm.controls['phoneNumber'].value.indexOf(' ') >= 0
      ) {
        phoneInternational =
          this.oobOtpChallengeForm.controls['phoneNumber'].value.split(' ')[1]
      } else {
        phoneInternational =
          this.oobOtpChallengeForm.controls['phoneNumber'].value
      }
      return phoneInternational
    } else {
      let phoneDomestic = ''
      if (
        this.oobOtpChallengeForm.controls['phoneNumber'].value.indexOf(' ') >= 0
      ) {
        phoneDomestic = this.oobOtpChallengeForm.controls['phoneNumber'].value
          .split(' ')[1]
          .substring(3)
      } else {
        phoneDomestic =
          this.oobOtpChallengeForm.controls['phoneNumber'].value.substring(3)
      }
      return phoneDomestic
    }
  }

  parseAreaCode() {
    let areaCode = ''
    if (this.isInternationalPhone) {
      return areaCode
    } else {
      if (
        this.oobOtpChallengeForm.controls['phoneNumber'].value.indexOf(' ') >= 0
      ) {
        areaCode = this.oobOtpChallengeForm.controls['phoneNumber'].value
          .split(' ')[1]
          .substring(0, 3)
      } else {
        areaCode = this.oobOtpChallengeForm.controls[
          'phoneNumber'
        ].value.substring(0, 3)
      }
    }
    return areaCode
  }

  retrieveTicketForUser() {
    this.loggerService.info(
      'Retrieving the ticket for OTP Enrollment journey for Poid: ' +
        this.session.poid,
    )
    this.channel = getChannel(
      this.oobOtpChallengeForm.controls['contactMethodRadio'].value,
    )
    this.transmitSdkService.isEnroll = true

    const primaryPhone =
      this.selectedCountryCode + this.parsedAreaCode + this.parsedPhoneNumber

    this.phone = primaryPhone

    this.transmitTicketPayload = {
      user_id: this.session.poid,
      policy_id: POLICY_ID,
      app_id: TICKET_APP_ID,
      car: false,
      extendedTimeOutIndicator: false,
      oobChallengeType: OOB_CHALLENGE_TYPE,
      bound: 'false',
      channel: this.channel,
      accountId: ACCOUNT_ID,
      serviceId: SERVICE_ID,
      message: '12345',
      transactionType: ENROLL_TRANSACTION_TYPE,
      userName: this.session.poid,
      userPrimaryPhoneNumber: primaryPhone,
      contactNumber: primaryPhone,
      journeyId: Transmit.OTP_ENROLLMENT,
      isEnrollment: true,
      poid: this.session.poid,
    }

    const ticketServiceResponse = this.ticketService.getTransmitTicket(
      TRANSMIT_TICKET_URL,
      this.transmitTicketPayload,
    )

    ticketServiceResponse.subscribe({
      next: (data: TransmitTicketResponse) => {
        this.session.transmitTicket = data.transmitTicket
        this.session.boundDevices = data.boundDevices
        this.loggerService.info(
          'Got the ticket for OTP enrollment journey: ' +
            data.transmitTicket +
            'for Poid: ' +
            this.session.poid,
        )
        this.initializeTransmitJourney()
      },

      error: (error) => {
        this.loggerService.error(
          '******Error occured while retrieving transmit ticket for OTP enrollment journey:' +
            'for Poid: ' +
            this.session.poid +
            ', Status: ' +
            error.status +
            ', error: ' +
            error.message,
        )
        this.ticketResponse = {
          transmitTicket: '',
          boundDevices: 0,
        }
        this.loading = false
        this.errorMsgService.invalidMessage = 'serviceFailureLogon'
        this.warningBanner.setWarningBannerMessage('serviceFailureLogon')
      },
    })
  }

  errorHandling() {
    this.errorMsgService.invalidMessage = 'serviceFailureLogon'
    this.viewService.setView(environment.USERNAME_AND_PASSWORD)
  }

  confirmCancel() {
    //Need to cancel the current journey, get a new ticket, and start a new journey if the user wants to sign up again
    sdk.cancelCurrentRunningControlFlow()
    this.session.clear()
    this.logonService.cancel(this.cancelRestEndPoint).subscribe(
      (data: CancelResponse) => {
        this.viewService.setView(environment.USERNAME_AND_PASSWORD)
      },
      (error) => {
        console.log(
          'Error occured while clearing session from Registration Step 1: Status: ' +
            error.status +
            ', error: ' +
            error.message,
        )
        this.viewService.setView(environment.USERNAME_AND_PASSWORD)
      },
    )
  }

  public closeModalDialog() {
    this.modalDialog.closeDialogModal()
  }

  public openModalDialog() {
    this.modalDialog.openModalDialog()
  }

  isPrettyUrl(): boolean {
    return window.location.origin.includes('institutional.vanguard.com')
  }
}

function getChannel(channel: any): string {
  switch (channel) {
    case 'SMS_TEXT':
      return 'SMS'
    case 'INTL_SMS_TEXT': //// confirm this value with Ramya
      return 'INTL_SMS'
    case 'AUTOMATED_CALL':
      return 'VOICE'
  }
}
