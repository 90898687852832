import { Session } from '../../session/session'
import { AaSessionAttributes } from '@app/models/AaSessionAttributes'

export class SessionService {
  private session: Session = {}

  get userName(): string {
    return this.session.userName
  }

  set userName(userName: string) {
    this.session.userName = userName
  }

  get question(): string {
    return this.session.question
  }

  set question(question: string) {
    this.session.question = question
  }

  get devicePrint(): string {
    return this.session.devicePrint
  }

  set devicePrint(devicePrint: string) {
    this.session.devicePrint = devicePrint
  }

  get correlationId(): string {
    return this.session.correlationId
  }

  set correlationId(correlationId: string) {
    this.session.correlationId = correlationId
  }

  get aaSessionAttributes(): AaSessionAttributes {
    return this.session.aaSessionAttributes
  }

  set aaSessionAttributes(aaSessionAttributes: AaSessionAttributes) {
    this.session.aaSessionAttributes = aaSessionAttributes
  }

  get selfService(): boolean {
    return this.session.selfService
  }

  set selfService(selfService: boolean) {
    this.session.selfService = selfService
  }

  get transmitTicket(): string {
    return this.session.transmitTicket
  }

  set transmitTicket(transmitTicket: string) {
    this.session.transmitTicket = transmitTicket
  }

  get boundDevices(): number {
    return this.session.boundDevices
  }

  set boundDevices(boundDevices: number) {
    this.session.boundDevices = boundDevices
  }

  get poid(): string {
    return this.session.poid
  }

  set poid(poid: string) {
    this.session.poid = poid
  }

  get phoneLast4Digits(): string {
    return this.session.phoneLast4Digits
  }

  set phoneLast4Digits(phoneLast4Digits: string) {
    this.session.phoneLast4Digits = phoneLast4Digits
  }

  get transmitEnabled(): boolean {
    return this.session.transmitEnabled
  }

  set transmitEnabled(transmitEnabled: boolean) {
    this.session.transmitEnabled = transmitEnabled
  }

  get auth1(): string {
    return this.session.auth1
  }

  set auth1(auth1: string) {
    this.session.auth1 = auth1
  }

  get auth2(): string {
    return this.session.auth2
  }

  set auth2(auth2: string) {
    this.session.auth2 = auth2
  }

  get transmitOobEnrolled(): boolean {
    return this.session.transmitOobEnrolled
  }

  set transmitOobEnrolled(transmitOobEnrolled: boolean) {
    this.session.transmitOobEnrolled = transmitOobEnrolled
  }

  get redirectUrl(): string {
    return this.session.redirectUrl
  }

  set redirectUrl(redirectUrl: string) {
    this.session.redirectUrl = redirectUrl
  }

  get oobBypass(): boolean {
    return this.session.oobBypass
  }

  set oobBypass(oobBypass: boolean) {
    this.session.oobBypass = oobBypass
  }

  get newUser(): boolean {
    return this.session.newUser
  }

  set newUser(newUser: boolean) {
    this.session.newUser = newUser
  }

  public clear() {
    this.session = { devicePrint: this.session.devicePrint }
  }
}
