
<spinner [active]="loading"></spinner>
<div *ngIf="!errorBanner" class="view-container">
    <div id="firstParagraph" class="margin-top-24 paragraph-verbiage margin-bottom-24 max-width-926">
        {{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.firstParagraph}}</div>
    <div class="paragraph-verbiage margin-bottom-24 max-width-926" id="secondParagrah">
        {{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.secondParagraph}}
        
        <a c11n-link class="link-without-href underline-border" role="link" tabindex="0" target="_blank" id="getMoreInfoLink" 
            (click)="securityCodesInfoContentDialog.openModalDialog($event)" (keydown.enter)="securityCodesInfoContentDialog.openModalDialog($event)"  [disableLink]="maxSessionsReached">
            <span>
                {{content.Data.oobSecurityPage.content.additionalInfo}}
            </span>
        </a>
        <c11n-modal-dialog
            [headingText]="content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.headerText"
            [maxWidth]="660"
            [primaryButtonLabel]="content.Data.cancelLayer.content.closeBtn"
            (primaryClick)="securityCodesInfoContentDialog.closeDialogModal($event)"
            (closeEvent)="securityCodesInfoContentDialog.closeDialogModal($event)"
            #securityCodesInfoContentDialog
            >
            <div c11n-modal-dialog-body>
                <ng-container *ngTemplateOutlet="ooptTipsBodyContentTemplate"></ng-container>
            </div>
        </c11n-modal-dialog>
        <ng-template #ooptTipsBodyContentTemplate>
            <oob-tips-dialog></oob-tips-dialog>
        </ng-template>
    </div>
    <div id="thirdParagraph" class="paragraph-verbiage margin-bottom-32 max-width-926">
        {{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.thirdParagraph}}
    </div>
    <div>

        <div class="inline-block">
            <c11n-checkbox class="c11n-checkbox-label" [isIndeterminate]="false" [labelText]="content.Data.registrationPage.content.acceptTermsAndCondition.acceptTermsAndCondition.termsConditionText" [isDisabled]="maxSessionsReached" 
                [variant]="'default'" [labelWeight]="'normal'">
                <input c11nCheckboxInput 
                class="c11n-checkbox__input" 
                aria-label="termsCheckbox"
                aria-describedby="termsCheckBoxError"
                id="termsCheckbox" 
                type="checkbox" 
                name="termsCheckbox" 
                [isDisabled]="maxSessionsReached"
                [(ngModel)]="checkedTC"/>
            </c11n-checkbox>
        </div>
      
        <a c11n-link class="link-without-href underline-border paragraph-verbiage" role="link" tabindex="1" target="_blank" id="oobTermsLink" 
            (click)="oobEnrollmentTermsConditionsDialog.openModalDialog($event)" (keydown.enter)="oobEnrollmentTermsConditionsDialog.openModalDialog($event)">
            <span>
                {{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobTermsLink}}
            </span>
        </a>

        <c11n-hint-error class="margin-left-22 margin-top-4"
            [hasError]="reviewTC && !checkedTC === true"
            [errorText]="content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.termsCondReviewTitle"
            [hintErrorId]="'termsCheckBoxError'">
        </c11n-hint-error>

        <c11n-modal-dialog
            [headingText]="content.Data.oobDomesticSecurityPage.content.titleId"
            [maxWidth]="660"
            [primaryButtonLabel]="content.Data.cancelLayer.content.closeBtn"
            (primaryClick)="oobEnrollmentTermsConditionsDialog.closeDialogModal($event)"
            (closeEvent)="oobEnrollmentTermsConditionsDialog.closeDialogModal($event)"
            #oobEnrollmentTermsConditionsDialog
            >
            <div c11n-modal-dialog-body>
                <ng-container *ngTemplateOutlet="internationalTermsContentTemplate"></ng-container>
            </div>
        </c11n-modal-dialog>

        <ng-template #internationalTermsContentTemplate>
            <international-oob-terms></international-oob-terms>
        </ng-template>
    </div>

    <div [hidden]="!checkedTC" id="oobBottomSection" class="font-family-UBR font-size-16 font-weight-normal margin-top-36 margin-bottom-24">
        <form [formGroup]="oobOtpChallengeForm" id="oobOtpChallenge" name="oobOtpChallenge">
            <h2 id="contactInfoHeader" class="c11n-28-800-32">
                {{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.headerText1}}
            </h2>
            <div class="flex-parent margin-top-24 margin-left-14 padding-bottom-12">
                <span>
                    <div class="row margin-bottom-28 ">
                        <c11n-select
                          (stateChange)="onSelectCountry($event)"
                          labelText="{{content.Data.oobSecurityPage.content.country}}"
                          hintText=""
                          size="medium"
                          tabindex="2"
                          errorText="{{content.Data.oobSecurityPage.content.selectionError}}"
                          [hasError]="showErrorMessage && oobOtpChallengeForm.controls.countryCode.invalid"
                          (focusout)="checkIfValid()"
                          >
                          <select c11nSelectInput id="countryCodeInput" formControlName="countryCode" required>
                            <option value="" data-option="placeholder" disabled selected>Country</option>
                            <option *ngFor="let country of listOfInternationalPhoneCodes" [value]="country">{{country.countryName}}</option>
                          </select>
                        </c11n-select>
                    </div>
                </span>
                <span class = "margin-left-28 mobile-margin-left">
                    <label c11n-label [for]="phoneNumber" id="phoneNumberLabel" > {{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.phoneNumber}} </label>
                    <c11n-input style="display: inline-flex;" [hasError]="oobOtpChallengeForm?.get('phoneNumber')?.touched && oobOtpChallengeForm.get('phoneNumber')?.errors">
                        <input c11nInput id="countryCodeSpan" style="border-right: 0px none;width: 80px;" value="{{plusSign}}{{selectedCountryCode}}" readonly="readonly" class="unselectable" />                        
                        <input 
                          c11nInput
                          style="border-left: 0px none;padding-left: 0px;"
                          inputmode="text"
                          id="phoneNumber" 
                          name="phoneNumber"
                          tabindex="3"
                          [countryPhoneCode]="selectedCountryCode"
                          aria-describedby="phoneNumberError"
                          formControlName="phoneNumber"
                          (keyup.enter)="otpChallenge()"
                          placeholder={{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.phoneNumber}}
                          />
                    </c11n-input>
                    <c11n-hint-error
                      [hasError]="oobOtpChallengeForm?.get('phoneNumber')?.touched && oobOtpChallengeForm?.get('phoneNumber')?.hasError('required')"
                      [errorText]="content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.emptyPhoneNumberValidation"
                      [hintErrorId]="'phoneNumberError'">
                    </c11n-hint-error>
                    <c11n-hint-error
                      [hasError]="(oobOtpChallengeForm?.get('phoneNumber')?.touched && !oobOtpChallengeForm?.get('phoneNumber')?.hasError('required')) && 
                                        (oobOtpChallengeForm?.get('phoneNumber')?.hasError('numOfDigitsMin') ||
                                         oobOtpChallengeForm?.get('phoneNumber')?.hasError('numOfDigitsMax') ||
                                         oobOtpChallengeForm?.get('phoneNumber')?.hasError('pattern'))"
                      [errorText]="content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.phoneNumberValidation"
                      [hintErrorId]="'phoneNumberError'">
                    </c11n-hint-error>                    
                 </span>
            </div>
            <div *ngIf="selectedCountryCode && selectedCountryCode !== undefined">
              <table *ngIf="!isInternationalPhone" id="contactInfo" class="mobile-margin-top-40">
                  <h2 id="contactMethodLabel"
                      class="c11n-17-700-24 padding-right-14 width-150 margin-bottom-24">
                      {{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.contactMethod}}
                  </h2>
                  <tr>
                      <td>
                          <c11n-radio-group [groupId]="'contactMethodOptions'" [hideLegend]="true" tabindex="4">
                            <div class="radio-element-wrapper margin-bottom-24">
                                <c11n-radio [labelText]="content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.text">
                                    <input c11nRadioInput id="textOptionDomestic" type="radio" [value]="'SMS_TEXT'" [checked]="true" formControlName="contactMethodRadio" />
                                </c11n-radio>
                            </div>
                            <div [hidden]="!isInternationalPhone" class="radio-element-wrapper margin-bottom-24">
                                <c11n-radio [labelText]="content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.text">
                                    <input c11nRadioInput id="textOptionIntl" type="radio" [value]="'INTL_SMS_TEXT'" formControlName="contactMethodRadio" />
                                </c11n-radio>
                            </div>
                            <div class="radio-element-wrapper margin-bottom-24">
                                <c11n-radio [labelText]="content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.call+' '+content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.domesticOnly">
                                    <input c11nRadioInput id="callOption" type="radio" [value]="'AUTOMATED_CALL'" formControlName="contactMethodRadio" />
                                </c11n-radio>
                            </div>
                          </c11n-radio-group>
                      </td>
                  </tr>
              </table>
              <div *ngIf="isInternationalPhone" id="internationalPhoneNote" class="margin-bottom-40">
                  <span class="c11n-17-700-24">{{content.Data.oobSecurityPage.content.internationalPhoneNoteBold}}&nbsp;</span>
                  <span class="c11n-17-400-24">{{content.Data.oobSecurityPage.content.internationalPhoneNote}}</span>
              </div>
              <ul id="additionalInfo" class="c11n-list c11n-17-400-24 margin-left-14">
                <li>
                  {{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.info1}} </li>
                <li>
                  {{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.info2}} </li>
                <li>
                  {{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.info3}} </li>
              </ul>
              <p id="frequencyHeader" class="c11n-17-700-24 margin-bottom-24 margin-top-40">
                  {{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.freqQuestion}}
              </p>
              <c11n-radio-group [groupId]="'rememberThisComputer'" name="frequencyInput" [hideLegend]="true" tabindex="5">
                <div class="radio-element-wrapper margin-bottom-24">
                    <c11n-radio [labelText]="content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.freqAlways">
                        <input c11nRadioInput id="freqAlways" type="radio" [value]="'ALWY'" [checked]="true" formControlName="frequencyRadio" />
                    </c11n-radio>
                </div>
                <div class="radio-element-wrapper margin-bottom-24">
                    <c11n-radio [labelText]="content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.freqUnbound">
                        <input c11nRadioInput id="freqUnbound" type="radio" [value]="'UBND'" formControlName="frequencyRadio" />
                    </c11n-radio>
                </div>
              </c11n-radio-group>
            </div>
          </form>

          <!-- OTP PopUp window -->
          <c11n-modal-dialog
            [headingText]="content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.title"
            [maxWidth]="660"
            (closeEvent)="closeDialog();modalDialog.closeDialogModal($event)"
            #modalDialog
            >
            <div c11n-modal-dialog-body>
                <ng-container *ngTemplateOutlet="otpPopUpContent"></ng-container>
            </div>
          </c11n-modal-dialog>

          <ng-template #otpPopUpContent>
              <div class="vui-dialog-body-content">
                <div id="popupErrorBanner" role="alert">
                  <div *ngIf="showPopUpMsg&&popUpMsg==='serviceFailure'" class="resend-status-div" id="serviceFailureMsg">
                      <c11n-banner id="sendCodeFailureMsg"
                            [size]="'large'"
                            [headingText]="content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.failureServiceMsgTitle"
                            [hasCloseControl]="false"
                            [hasDetails]="true"
                            [closeAriaLabel]="closeAriaLabel"
                            [variant]="'error'"
                            [isDetailsExpanded]="true"
                        >
                        <div>{{content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.failureServiceMsgSubtitle}}</div>
                      </c11n-banner>
                  </div>
                  <div *ngIf="showPopUpMsg&&(popUpMsg==='maxSessions')" class="resend-status-div">
                        <c11n-banner id="maxSessionsMsgBanner"
                            [size]="'large'"
                            [headingText]="content.Data.oobSecurityPage.content.errorMsg.errorMsg.errorTitle"
                            [hasCloseControl]="false"
                            [hasDetails]="true"
                            [closeAriaLabel]="closeAriaLabel"
                            [variant]="'error'"
                            [isDetailsExpanded]="true"
                        >
                        <div>{{content.Data.oobSecurityPage.content.errorMsg.errorMsg.maxSessionMsg}}</div>
                      </c11n-banner>
                  </div>
                  <div *ngIf="showPopUpMsg&&popUpMsg==='resendSuccess'" class="resend-status-div c11n-17-700-24">
                      <div id="sendCodeSuccessMsg">
                        <c11n-banner id="codeSuccessMsg"
                            [size]="'medium'"
                            [headingText]="content.Data.oobSecurityPage.content.successSendCode"
                            [hasCloseControl]="false"
                            [hasDetails]="false"
                            [closeAriaLabel]="closeAriaLabel"
                            [variant]="'success'"
                            [isDetailsExpanded]="false"
                            >
                        </c11n-banner> 
                      </div>
                  </div>
                  <div *ngIf="showPopUpMsg&&popUpMsg==='invalidCode'" class="resend-status-div c11n-17-700-24">
                      <c11n-banner id="invalidCodeMsg"
                            [size]="'medium'"
                            [headingText]="content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.validationFailureServiceSubmitMsgTitle"
                            [hasCloseControl]="false"
                            [hasDetails]="true"
                            [closeAriaLabel]="closeAriaLabel"
                            [variant]="'error'"
                            [isDetailsExpanded]="true"
                        >
                        <div>{{content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.validationFailureServiceSubmitMsgSubtitle1}}</div>
                        <div>{{content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.validationFailureServiceSubmitMsgSubtitle2}}</div>
                      </c11n-banner>
                  </div>
                  
                  <div *ngIf="showPopUpMsg&&popUpMsg==='expiredCode'" class="resend-status-div c11n-17-700-24">
                      <c11n-banner id="expiredCodeMsg"
                            [size]="'medium'"
                            [headingText]="content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.securityCodeExpiredMsgTitle"
                            [hasCloseControl]="false"
                            [hasDetails]="false"
                            [closeAriaLabel]="closeAriaLabel"
                            [variant]="'error'"
                            [isDetailsExpanded]="true"
                        >
                        <div>{{content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.securityCodeExpiredMsgSubtitle}}</div>
                      </c11n-banner>
                  </div>
                </div>
                  <p class="pre-line-white-space c11n-17-400-24">
                      {{content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.subtext}}</p>
                  <form [formGroup]="oobEnrollmentForm" id="oobEnrollment" name="oobEnrollment">
                      <div class="margin-bottom-12 margin-top-32">
                          <span id="phoneNumberLabel" for="phoneNumber"
                              class="c11n-17-700-24 padding-right-14">{{content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.phoneNumberLabel}}</span>
                          <span id="phoneNumber"
                              class="c11n-17-400-24 margin-left-32 mobile">
                              {{phoneNumberFormater(oobOtpChallengeForm.controls['phoneNumber'].value)}}
                          </span>
                      </div>
                      <div class="margin-bottom-32">
                          <span id="contactMethodLabel" for="contactMethod"
                              class="c11n-17-700-24">{{content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.contactMethodLabel}}</span>
                          <span *ngIf="oobOtpChallengeForm.controls['contactMethodRadio'].value === 'SMS_TEXT'"
                              id="contactMethod"
                              class="c11n-17-400-24 margin-left-32">{{content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.contactMethodText}}</span>
                          <span *ngIf="oobOtpChallengeForm.controls['contactMethodRadio'].value === 'INTL_SMS_TEXT'"
                              id="contactMethod"
                              class="c11n-17-400-24 margin-left-32">{{content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.contactMethodText}}</span>
                          <span *ngIf="oobOtpChallengeForm.controls['contactMethodRadio'].value === 'AUTOMATED_CALL'"
                              id="contactMethod"
                              class="c11n-17-400-24 margin-left-32 mobile">{{content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.contactMethodCall}}</span>
                      </div>
                      <div class="margin-bottom-0 width-306">

                        <div class="security-code-input-box">
                            <label 
                                c11n-label [for]="'oobCode'" id="oobCodeLabel" class="orced-oob-enroll-c11n-label">{{content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.securityCodeLabel}}
                            </label>
                            <c11n-input [hasError]="oobEnrollmentForm?.get('oobCode')?.touched && oobEnrollmentForm.get('oobCode')?.errors">
                                <input 
                                    c11nInput
                                    inputmode="text"
                                    id="oobCodeInput" 
                                    name="oobCode"
                                    aria-describedby="oobCodeError"
                                    formControlName="oobCode"
                                    placeholder={{content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.securityCodePlaceholder}}
                                    (keyup.enter)="oobEnroll()"
                                />
                            </c11n-input>    
                            <c11n-hint-error
                                [hasError]="oobEnrollmentForm?.get('oobCode')?.touched && oobEnrollmentForm?.get('oobCode')?.hasError('required')"
                                [errorText]="content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.requiredField"
                                [hintErrorId]="'oobCodeError'">
                            </c11n-hint-error>
                            <c11n-hint-error
                                [hasError]="oobEnrollmentForm?.get('oobCode')?.touched && 
                                                    (oobEnrollmentForm?.get('oobCode')?.hasError('pattern') ||
                                                     oobEnrollmentForm?.get('oobCode')?.hasError('maxlength'))"
                                [errorText]="content.Data.oobSecurityPage.content.fieldValidationMsg.fieldValidationMsg.oobCodeInvalidFormat"
                                [hintErrorId]="'oobCodeError'">
                            </c11n-hint-error>
                        </div>
                        <div class="nowrap-white-space">  
                            <a c11n-link class="link-without-href forced-oob-enroll-send-new-code inline-block" role="link" tabindex="9" target="_blank" id="sendNewCode" (click)="sendNewOtp()" (keyup.enter)="sendNewOtp()" 
                                (sendCode)="sendNewOtp()" [disableLink]="disableLink">
                                <span class="inline-block">{{content.Data.oobSecurityPage.content.reSendSecurityCode}}</span>
                            </a>
                        </div>
                      </div>
                  </form>
                  <div class="d-flex margin-top-36">
                    <div>
                        <c11n-button 
                            id="closePopUpOtp" 
                            buttonType="button"
                            labelText={{content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.cancelBtn}}
                            variant="secondary"
                            tabindex="6"
                            (clickEvent)="closeDialog();modalDialog.closeDialogModal($event)" (keydown.enter)="closeDialog();modalDialog.closeDialogModal($event)"
                        >
                        </c11n-button>
                    </div>
                    <c11n-button class="margin-left-40" 
                        id="submitPopUpOtp"
                        name="submitPopUpOtp"
                        buttonType="button"
                        labelText={{content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.submitBtn}}
                        variant="primary"
                        tabindex="7"
                        [isInvalid]="maxSessionsReached"
                        (clickEvent)="oobEnroll()"
                        >
                    </c11n-button>
                </div>
              </div>
          </ng-template>
    </div>
    <div class="d-flex  margin-bottom-80 margin-top-64">
        <div>
            <c11n-button 
                id="cancelBtn" 
                buttonType="button"
                labelText={{content.Data.oobSecurityPage.content.cancelBtn}}
                variant="secondary"
                (clickEvent)="cancelModalDialog.openModalDialog($event)" (keydown.enter)="cancelModalDialog.openModalDialog($event)"
            >
            </c11n-button>
            <div id="cancelDialogBoxIdSignUpSecCodes">
                
                <c11n-modal-dialog
                    modalid="cancelModalDialog" #cancelModalDialog
                    [headingText]="content.Data.cancelLayer.content.cancelLayerQuestion"
                    [primaryButtonLabel]="content.Data.cancelLayer.content.confirmCanceNo"
                    [secondaryButtonLabel]="content.Data.cancelLayer.content.confirmCanceYes"
                    variant="warning" 
                    (primaryClick)="cancelModalDialog.closeDialogModal($event)"
                    (secondaryClick)="confirmCancel()"
                    [maxWidth]="660"
                >
                    <div c11n-modal-dialog-body>
                        <ng-container *ngTemplateOutlet="cancelDialog"></ng-container>
                    </div>
                </c11n-modal-dialog>
            </div>
            <ng-template #cancelDialog>
                <p class="c11n-cancel-subtext">{{content.Data.cancelLayer.content.cancelLayerSubtext}}</p>
            </ng-template>
        </div>
        <c11n-button class="margin-left-40 continue-btn" 
            id="signUpBtn"
            name="signUpBtn"
            buttonType="button"
            labelText={{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.signUpBtn}}
            variant="primary"
            (clickEvent)="otpChallenge()"
            >
        </c11n-button>
    </div>
</div>

<div *ngIf="errorBanner" class="changeErrorViewMain">
  <c11n-banner id="serviceFailureMessagePhone"
    [size]="'large'"
    [headingText]="content.Data.registrationPage.content.serviceFailureMessage"
    [hasCloseControl]="false"
    [hasDetails]="false"
    [closeAriaLabel]="closeAriaLabel"
    [variant]="'success'"
    [isDetailsExpanded]="false"
    >
  </c11n-banner>
</div>
